import { createStore } from 'vuex'
import axios from 'axios';
import router from '../router'
var API_URL = {
  login:"/login",
  logout:"/logout",
  resetPassword:"/reset-password",
  navigationItem:"/navigation-item/list",
  orgList:"/organization/list",
  // tankMeasurement 
  tankMeasurementList:"tank-measurement/list",
  tankMeasurementImport:"tank-measurement/import",
  tankMeasurementDelete:"tank-measurement/delete",
  // area
  areaList:"/area/list",
  areaUpdate:"/area/update",
  areaDelete:"/area/delete",
  areaCreate:"/area/create",
  //department
  departmentList:"/department/list",
  departmentUpdate:"/department/update",
  departmentDelete:"/department/delete",
  departmentCreate:"/department/create",
  // user
  userList:"/user/list",
  userUpdate:"/user/update",
  userDelete:"/user/delete",
  userCreate:"/user/create",
  // tank
  tankList:"/tank/list",
  tankUpdate:"/tank/update",
  tankDelete:"/tank/delete",
  tankCreate:"/tank/create",

  storageFile:"/storage/file"
}
export default createStore({
  state: {
    loading:false,
    DOMAIN_URL :'', 
    userInfo:{
      token:"",
      email:"",
      password:"",
      name:"", 
    },
    generatePassword:{},
    loginInfo:{},
    token: '', 
    sideBar: [], 
    ChangePswInfo:{}, 
    displayBase64Img:null,  
    tankBase64Img:null,  
    tankMeasurementFileBase64:null,   
    org:{
      dropDown:[],  
      search:[],  
      update:[],  
      delete:[],  
      create:[],    
    },
    area:{
      dropDown:[],  
      search:[],  
      update:[],  
      delete:[],  
      create:[],    
    },
    department:{
      dropDown:[],  
      search:[],  
      update:[],  
      delete:[],  
      create:[],    
    },
    user:{
      dropDown:[],  
      search:[],  
      update:[],  
      delete:[],  
      create:[],    
    },
    tank:{
      dropDown:[],  
      search:[],  
      update:[],  
      delete:[],  
      create:[],    
    },
    tankMeasurement:{
      dropDown:[],  
      search:[],  
      update:[],  
      delete:[],  
      create:[],
      import:[]    
    },
  },
  mutations: {
    SetLoginInfo(state,payload){
      state.loginInfo = payload;  
    },
    SetLoginToken(state,payload) {
      state.token = payload;  
      localStorage.setItem("userInfo", JSON.stringify(state.userInfo));  
      localStorage.setItem("role", JSON.stringify(state.role));  
      this.dispatch('GetSideBar');
    },
    DeleteLoginToken(state) {
      state.userInfo.token="";
      state.userInfo.password="";
      state.userInfo.role="";
      state.token = "";
      localStorage.setItem("userInfo", JSON.stringify(state.userInfo)); 
      router.push({ name: 'login' })
    },
    SetSideBar(state, payload) {   
      state.sideBar = payload;
      // this.dispatch('GetAllPermissions');
    },
    SetChangePsw(state, payload) { 
      state.ChangePswInfo = payload;//監聽並關閉視窗
    },  
    /*--dropDown--*/
    SetDropDownOrgList(state, payload) {
      state.org.dropDown = payload;
    }, 
    SetSearchTankMeasurementList(state, payload) {
      state.tankMeasurement.search = payload; 
    },  
    SetImportTankMeasurement(state, payload) { 
      state.tankMeasurement.import = payload; 
    },  
    SetDeleteTankMeasurement(state, payload) { 
      state.tankMeasurement.delete = payload; 
    },  
    SetDisplayBase64Img(state, payload) { 
      state.displayBase64Img = payload; 
    },  
    SetTankBase64Img(state, payload) { 
      state.tankBase64Img = payload; 
    },  
    SetTankMeasurementFileBase64(state, payload) { 
      state.tankMeasurementFileBase64 = payload; 
    },  
    ShowLoaderPanel(state, payload) {
      state.loading= payload; 
    }, 
    /*--area--*/
    SetDropDownArea(state, payload) {
      state.area.dropDown = payload;
    },  
    SetSearchArea(state, payload) {
      state.area.search = payload;
    }, 
    SetCreateArea(state, payload) {
      state.area.create = payload;
    }, 
    SetUpdateArea(state, payload) {
      state.area.update = payload;
    }, 
    SetDeleteArea(state, payload) {
      state.area.delete = payload;
    },
    /*--department--*/
    SetDropDownDepartment(state, payload) {
      state.department.dropDown = payload;
    },  
    SetSearchDepartment(state, payload) {
      state.department.search = payload;
    }, 
    SetCreateDepartment(state, payload) {
      state.department.create = payload;
    }, 
    SetUpdateDepartment(state, payload) {
      state.department.update = payload;
    }, 
    SetDeleteDepartment(state, payload) {
      state.department.delete = payload;
    },
    /*--user--*/
    SetSearchUser(state, payload) {
      state.user.search = payload;
    }, 
    SetCreateUser(state, payload) {
      state.user.create = payload;
    }, 
    SetUpdateUser(state, payload) {
      state.user.update = payload;
    }, 
    SetDeleteUser(state, payload) {
      state.user.delete = payload;
    },  
    /*--tank--*/
    SetDropDownTankList(state, payload) {
      state.tank.dropDown = payload;
    },  
    SetSearchTank(state, payload) {
      state.tank.search = payload;
    }, 
    SetCreateTank(state, payload) {
      state.tank.create = payload;
    }, 
    SetUpdateTank(state, payload) {
      state.tank.update = payload;
    }, 
    SetDeleteTank(state, payload) {
      state.tank.delete = payload;
    },  
  },
  getters: {
    // CheckPermissions: (state) => (key) => { 
    //   for(var i in state.allPermissions){
    //     if(state.allPermissions[i].name==key)return true;
    //   }
    //   return false; 
    // }
  },
  actions: {  
    GetLoginToken({ commit }, payload) {
      axios.post(API_URL.login,
        {
          "email": payload.email,
          "g-recaptcha-response": payload['g-recaptcha-response'],
          "password": payload.password
        }
      ).then(result => { 
        commit('SetLoginInfo',result.data); 
        if (result.data.header.message == "Success") {
          this.state.userInfo.password=payload.saveToken? payload.password:"";
          this.state.userInfo.token=result.data.data.access_tokens;
          this.state.userInfo.name=result.data.data.name;
          this.state.userInfo.email=payload.email;  
          this.state.userInfo.role=result.data.data.role;  

          commit('SetLoginToken',result.data.data.access_tokens); 
          router.push({ name: 'TankMeasurement' })
        }

      }).catch(error => {
        throw new Error(`API ${error}`);
      });
    },
    GetSideBar({ commit }) { 
      // var map ='https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyBnKeSQcGExfp7jk21UZ1wkdmmxgsci0M8';
      // axios.post(map,
      //   {
      //     "page": 1,
      //     "count": 100,
      //     "sort_field": "id",
      //     "sort_order": "asc",
      //     "is_output": 0
      //   }, {
      //   headers: {
      //     Authorization: `Bearer ${this.state.token}` // Bearer 跟 token 中間有一個空格
      //   }
      // })

      axios.post(API_URL.navigationItem,
        {
          "page": 1,
          "count": 100,
          "sort_field": "id",
          "sort_order": "asc",
          "is_output": 0
        }, {
        headers: {
          Authorization: `Bearer ${this.state.token}` // Bearer 跟 token 中間有一個空格
        }
      })
        .then(result => {  
          if (result.data.header.message == "Success") {
            commit('SetSideBar', result.data.data.list);
          }
        }).catch(error => {
          this.dispatch('Logout');
          throw new Error(`API ${error}`);
        });
    },
    Logout({ commit }) { 
      axios.post(API_URL.logout,
        {}, 
        {
        headers: {
          Authorization: `Bearer ${this.state.token}` // Bearer 跟 token 中間有一個空格
        }
      })
        .then(result => {
          result;
        }).catch(error => {
          throw new Error(`API ${error}`);
        }); 
        commit('DeleteLoginToken');
    },
    ChangePsw({commit},payload){
      commit('SetChangePsw', '');
      axios.post(API_URL.resetPassword,
        payload, 
        {
        headers: {
          Authorization: `Bearer ${this.state.token}` // Bearer 跟 token 中間有一個空格
        }
      }
      ).then(result => { 
        if (result.data.header.message == "Success") {   
          if(this.state.userInfo.password){
            this.state.userInfo.password = payload.password;
            localStorage.setItem("userInfo", JSON.stringify(this.state.userInfo)); 
          }  
        } 
        commit('SetChangePsw', result); 
      }).catch(error => {
        throw new Error(`API ${error}`);
      });
    }, 
    /*--dropDown--*/
    GetDropDownOrgList({ commit },payload) { 
      axios.post(API_URL.orgList,
        {
          "page": 1,
          "count": 10,
          "sort_field": "id",
          "sort_order": "desc",
          "search" : payload,
          "is_dropdown":1,
          "is_output": 0
      }, 
        {
        headers: {
          Authorization: `Bearer ${this.state.token}` // Bearer 跟 token 中間有一個空格
        }
      })
        .then(result => { 
          if (result.data.header.message == "Success") {
            commit('SetDropDownOrgList',result.data.data.list);
          }
        }).catch(error => {
          throw new Error(`API ${error}`);
        });
    }, 
    // /*--search--*/
    GetSearchTankMeasurementList({ commit },payload) { 
      // commit('SetSearchDisplayList',{});
      axios.post(API_URL.tankMeasurementList, payload, 
        {
        headers: {
          Authorization: `Bearer ${this.state.token}` // Bearer 跟 token 中間有一個空格
        }
      })
        .then(result => { 
          if (result.data.header.message == "Success") {
            commit('SetSearchTankMeasurementList',result.data.data);
          }
        }).catch(error => {
          throw new Error(`API ${error}`);
        });
    }, 
    GetImportTankMeasurement({ commit },payload) {
      commit('ShowLoaderPanel',true); 
      axios.post(API_URL.tankMeasurementImport, payload,
        {
          timeout: 1000*60*60*24,
          headers: 
          {
            'Content-Type':'multipart/form-data',
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {     
            commit('SetImportTankMeasurement',result);  
            commit('ShowLoaderPanel',false); 
        }).catch(error => { 
          throw new Error(`API ${error}`);
        });
    },
    GetDeleteTankMeasurement({ commit },payload) {
      axios.post(API_URL.tankMeasurementDelete , payload,
        {
          headers: 
          {
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {    
            commit('SetDeleteTankMeasurement',result);  
        }).catch(error => { 
          throw new Error(`API ${error}`);
        });
    }, 
    GetDisplayBase64Img({ commit },payload) {  
      commit('SetDisplayBase64Img',null);
      axios.post(API_URL.storageFile,
        {
          "folder": "tankMeasurementDetail",
          "filename": payload
      }, 
        {
        headers: {
          Authorization: `Bearer ${this.state.token}` // Bearer 跟 token 中間有一個空格
        }
      })
        .then(result => {  
            commit('SetDisplayBase64Img',result.data); 
        }).catch(error => {
          throw new Error(`API ${error}`);
        });
    },  
    GetTankBase64Img({ commit },payload) {  
      commit('SetTankBase64Img',null);
      axios.post(API_URL.storageFile,
        {
          "folder": "tanks",
          "filename": payload
      }, 
        {
        headers: {
          Authorization: `Bearer ${this.state.token}` // Bearer 跟 token 中間有一個空格
        }
      })
        .then(result => {  
            commit('SetTankBase64Img',result.data); 
        }).catch(error => {
          throw new Error(`API ${error}`);
        });
    },  
    GetTankMeasurementFileBase64({ commit },payload) {  
      commit('SetTankMeasurementFileBase64',null);
      axios.post(API_URL.storageFile,
        {
          "folder": "tankMeasurements",
          "filename": payload
      }, 
        {
        headers: {
          Authorization: `Bearer ${this.state.token}` // Bearer 跟 token 中間有一個空格
        }
      })
        .then(result => {  
            commit('SetTankMeasurementFileBase64',result.data); 
        }).catch(error => {
          throw new Error(`API ${error}`);
        });
    },   
    //GetDropDownArea
    // GetDropDownArea({ commit },payload) {
    //   axios.post(API_URL.areaList,
    //     {
    //       "page": 1,
    //       "count": 1000,
    //       "sort_field": "id",
    //       "sort_order": "desc",
    //       "search" : payload,
    //       "is_dropdown":1,
    //       "is_output": 0
    //   }, 
    //     {
    //     headers: {
    //       Authorization: `Bearer ${this.state.token}` // Bearer 跟 token 中間有一個空格
    //     }
    //   })
    //     .then(result => { 
    //       if (result.data.header.message == "Success") {
    //         commit('SetDropDownArea',result.data.data.list);
    //       }
    //     }).catch(error => {
    //       throw new Error(`API ${error}`);
    //     });
    // }, 

    /*--Area--*/  
    GetDropDownArea({ commit },payload) {
      axios.post(API_URL.areaList, payload,
        {
          headers: 
          {
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {   
          if (result.data.header.message == "Success") {
            commit('SetDropDownArea',result.data.data.list); 
          }
        }).catch(error => { 
          throw new Error(`API ${error}`);
        });
    }, 
    GetSearchArea({ commit },payload) { 
      axios.post(API_URL.areaList, payload,
        {
          headers: 
          {
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {   
          if (result.data.header.message == "Success") {
            commit('SetSearchArea',result.data.data); 
          }
        }).catch(error => {
 
          throw new Error(`API ${error}`);
        });
    },
    GetCreateArea({ commit },payload) { 
      commit('ShowLoaderPanel',true);
      axios.post(API_URL.areaCreate, payload,
        {
          headers: 
          {
            'Content-Type':'multipart/form-data',
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {    
            commit('SetCreateArea',result);  
            commit('ShowLoaderPanel',false);
            // commit('ShowAlertPanel',result);
        }).catch(error => { 
          throw new Error(`API ${error}`);
        });
    },
    GetDeleteArea({ commit },payload) {
      axios.post(API_URL.areaDelete , payload,
        {
          headers: 
          {
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {    
            commit('SetDeleteArea',result);  
        }).catch(error => { 
          throw new Error(`API ${error}`);
        });
    },
    GetUpdateArea({ commit },payload) {
      axios.post(API_URL.areaUpdate, payload,
        {
          headers: 
          {
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {    
            commit('SetUpdateArea',result);  
        }).catch(error => { 
          throw new Error(`API ${error}`);
        });
    }, 
    /*--department Department--*/  
    GetDropDownDepartment({ commit },payload) {
      axios.post(API_URL.departmentList, payload,
        {
          headers: 
          {
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {   
          if (result.data.header.message == "Success") {
            commit('SetDropDownDepartment',result.data.data.list); 
          }
        }).catch(error => { 
          throw new Error(`API ${error}`);
        });
    }, 
    GetSearchDepartment({ commit },payload) { 
      axios.post(API_URL.departmentList, payload,
        {
          headers: 
          {
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {   
          if (result.data.header.message == "Success") {
            commit('SetSearchDepartment',result.data.data); 
          }
        }).catch(error => {
 
          throw new Error(`API ${error}`);
        });
    },
    GetCreateDepartment({ commit },payload) { 
      commit('ShowLoaderPanel',true);
      axios.post(API_URL.departmentCreate, payload,
        {
          headers: 
          {
            'Content-Type':'multipart/form-data',
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {    
            commit('SetCreateDepartment',result);  
            commit('ShowLoaderPanel',false);
            // commit('ShowAlertPanel',result);
        }).catch(error => { 
          throw new Error(`API ${error}`);
        });
    },
    GetDeleteDepartment({ commit },payload) {
      axios.post(API_URL.departmentDelete , payload,
        {
          headers: 
          {
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {    
            commit('SetDeleteDepartment',result);  
        }).catch(error => { 
          throw new Error(`API ${error}`);
        });
    },
    GetUpdateDepartment({ commit },payload) {
      axios.post(API_URL.departmentUpdate, payload,
        {
          headers: 
          {
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {    
            commit('SetUpdateDepartment',result);  
        }).catch(error => { 
          throw new Error(`API ${error}`);
        });
    }, 
    /*--User--*/  
    GetDropDownUser({ commit },payload) {
      axios.post(API_URL.userList, payload,
        {
          headers: 
          {
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {   
          if (result.data.header.message == "Success") {
            commit('SetDropDownUser',result.data.data.list); 
          }
        }).catch(error => { 
          throw new Error(`API ${error}`);
        });
    }, 
    GetSearchUser({ commit },payload) { 
      axios.post(API_URL.userList, payload,
        {
          headers: 
          {
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {   
          if (result.data.header.message == "Success") {
            commit('SetSearchUser',result.data.data); 
          }
        }).catch(error => {
 
          throw new Error(`API ${error}`);
        });
    },
    GetCreateUser({ commit },payload) { 
      commit('ShowLoaderPanel',true);
      axios.post(API_URL.userCreate, payload,
        {
          headers: 
          {
            'Content-Type':'multipart/form-data',
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {    
            commit('SetCreateUser',result);  
            commit('ShowLoaderPanel',false);
            // commit('ShowAlertPanel',result);
        }).catch(error => { 
          throw new Error(`API ${error}`);
        });
    },
    GetDeleteUser({ commit },payload) {
      axios.post(API_URL.userDelete , payload,
        {
          headers: 
          {
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {    
            commit('SetDeleteUser',result);  
        }).catch(error => { 
          throw new Error(`API ${error}`);
        });
    },
    GetUpdateUser({ commit },payload) {
      axios.post(API_URL.userUpdate, payload,
        {
          headers: 
          {
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {    
            commit('SetUpdateUser',result);  
            if (result.data.header.message == "Success") { 
              if(this.state.userInfo.email ==payload.email){
              this.state.userInfo.name=payload.name;   
              localStorage.setItem("userInfo", JSON.stringify(this.state.userInfo));  
              }
            }
        }).catch(error => { 
          throw new Error(`API ${error}`);
        });
    }, 
    /*--Tank--*/  
  GetDropDownTank({ commit },payload) {
    axios.post(API_URL.tankList,
      {
        "page": 1,
        "count": 1000,
        "sort_field": "id",
        "sort_order": "desc",
        "search" : payload,
        "is_dropdown":1,
        "is_output": 0
    }, 
      {
      headers: {
        Authorization: `Bearer ${this.state.token}` // Bearer 跟 token 中間有一個空格
      }
    })
      .then(result => { 
        if (result.data.header.message == "Success") {
          commit('SetDropDownTankList',result.data.data.list);
        }
      }).catch(error => {
        throw new Error(`API ${error}`);
      });
  },
  GetSearchTank({ commit },payload) { 
    axios.post(API_URL.tankList, payload,
      {
        headers: 
        {
          Authorization: `Bearer ${this.state.token}`  
        }
      })
      .then(result => {   
        if (result.data.header.message == "Success") {
          commit('SetSearchTank',result.data.data); 
        }
      }).catch(error => {

        throw new Error(`API ${error}`);
      });
  },
  GetCreateTank({ commit },payload) { 
    commit('ShowLoaderPanel',true);
    axios.post(API_URL.tankCreate, payload,
      {
        headers: 
        {
          'Content-Type':'multipart/form-data',
          Authorization: `Bearer ${this.state.token}`  
        }
      })
      .then(result => {    
          commit('SetCreateTank',result);  
          commit('ShowLoaderPanel',false);
          // commit('ShowAlertPanel',result);
      }).catch(error => { 
        throw new Error(`API ${error}`);
      });
  },
  GetDeleteTank({ commit },payload) {
    axios.post(API_URL.tankDelete , payload,
      {
        headers: 
        {
          Authorization: `Bearer ${this.state.token}`  
        }
      })
      .then(result => {    
          commit('SetDeleteTank',result);  
      }).catch(error => { 
        throw new Error(`API ${error}`);
      });
  },
  GetUpdateTank({ commit },payload) {
    axios.post(API_URL.tankUpdate, payload,
      {
        headers: 
        {
          Authorization: `Bearer ${this.state.token}`  
        }
      })
      .then(result => {    
          commit('SetUpdateTank',result);  
      }).catch(error => { 
        throw new Error(`API ${error}`);
      });
  }, 
  },
  modules: {
  }
})
