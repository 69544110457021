<template>
    <div v-if="Object.keys(measureData).length == 0" class="container-fluid p-4">
        <div class="row mt-5" style="
    margin-top: 15vh;">
            <div class="col-12 mb-2">
                <h1 style="text-align: center;font-weight: 600;font-size: calc(1.75rem + 1.5vw);">ACR Gateway</h1>
            </div>
            <div v-if="$store.state.userInfo.role.indexOf('Admin') != -1"
                class="col-12  offset-md-2 col-md-8  offset-lg-3 col-lg-6 mb-2 multiselect-group">
                <label class="form-lable">組 織</label>
                <Multiselect v-model="searchInfo.org" placeholder="請選擇" :options="dropDownOrgList" track-by="id"
                    :show-labels="false" label="name" :searchable="true" :allow-empty="true">
                </Multiselect>
            </div>
        </div>
        <div class="row">
            <div class="col-12 offset-md-2 col-md-8  offset-lg-3 col-lg-6 mb-2 multiselect-group">
                <label class="form-lable">地 區</label>
                <Multiselect v-model="searchInfo.area" placeholder="請選擇" :options="dropDownArea" track-by="id"
                    :show-labels="false" label="name" :searchable="true" :allow-empty="true">
                </Multiselect>
            </div>
        </div>
        <div class="row">
            <div class="col-12  offset-md-2 col-md-8 offset-lg-3  col-lg-6 mb-2 multiselect-group">
                <label class="form-lable">桶 槽</label>
                <Multiselect v-model="searchInfo.tank" placeholder="請選擇" :options="dropDownTankList" track-by="id"
                    :show-labels="false" label="name" :searchable="true" :allow-empty="true">
                </Multiselect>
            </div>
        </div>
        <div class="row">
            <div class="col-12 ">
                <div class="errMsg">{{ msg }}</div>
                <button class="search-btn" @click="searchDisplay" style="margin: 15px auto;">
                    搜尋
                </button>
                <!-- <div v-if="Object.keys(searchTankMeasurementList).length != 0">
                    {{ searchTankMeasurementList }}
                </div> -->
            </div>
        </div>
    </div>
    <div v-if="Object.keys(measureData).length != 0 && !isInit" class="container-fluid p-4">
        <div class="row">
            <CanvasView :key="threeViewVueKey" v-bind:measure-data="measureData" v-bind:close-view="closeThreeView">
            </CanvasView>
        </div>
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import CanvasView from '../../components/display/CanvasView.vue';
export default {
    searchUserList: {},
    name: 'HcpUpload',
    components: {
        // DataTable,
        // PaginationVue
        CanvasView
    },
    data() {
        return {
            isInit: true,
            msg: "",
            threeViewVueKey: 1,
            searchInfo: {
                org: null,
                area: null,
                tank: null,
            },
            measureData: {},
            filterInfo: {}
        };
    },
    watch: {
        'searchInfo.org': function (val) {
            if (val != null) {
                this.GetDropDownArea(
                    {
                        "page": 1,
                        "count": 1000,
                        "sort_field": "id",
                        "sort_order": "desc",
                        "search": {
                            organization_id: [val.id]
                        },
                        "is_dropdown": 1,
                        "is_output": 0
                    }
                );
            }
            this.searchInfo.area = null
            this.searchInfo.tank = null
        },
        'searchInfo.area': function (val) {
            if (val != null) {
                this.GetDropDownTank({
                    area_id: [val.id]
                });
            }
            this.searchInfo.tank = null

        },
        searchTankMeasurementList: function (val) {
            this.msg = ""
            if (val.list && val.list[0])
                this.measureData = val;
            else this.msg = "此桶槽無測量資料"
        }
    },
    mounted() {
        // this.closeThreeView();
        this.GetDropDownArea(
            {
                "page": 1,
                "count": 1000,
                "sort_field": "id",
                "sort_order": "desc",
                "search": {
                },
                "is_dropdown": 1,
                "is_output": 0
            }
        );
        this.GetDropDownOrgList({});
    },
    computed: {
        ...mapState(
            {
                dropDownOrgList: state => state.org.dropDown,
                dropDownArea: state => state.area.dropDown,
                dropDownTankList: state => state.tank.dropDown,
                searchTankMeasurementList: state => state.tankMeasurement.search,
            }
        ),
    },
    methods: {
        ...mapActions([
            'GetDropDownOrgList',
            'GetDropDownArea',
            'GetDropDownTank',
            'GetSearchTankMeasurementList',
        ]),
        searchDisplay() {
            this.msg = ' '
            if (!this.searchInfo.tank) {
                this.msg = '請填寫完整桶槽資訊';
                this.$store.commit('SetSearchTankMeasurementList', {})
                return
            } else {
                this.isInit = false;
                this.GetSearchTankMeasurementList(
                    {
                        "page": 1,
                        "count": 10,
                        "sort_field": "id",
                        "sort_order": "desc",
                        "search": {
                            tank_id: [this.searchInfo.tank.id]
                        },
                        "is_output": 0
                    })
            }
            this.threeViewVueKey++;
        },
        closeThreeView() {
            this.isInit = true;
            this.measureData = {};
            this.threeViewVueKey++;
            this.msg = ""
        }
    }
}
</script>