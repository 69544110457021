<template>
    <div class="container-fluid">
        <div class="row px-md-4 my-3">
            <div class="col-4 col-lg-2 mb-2">
                <label class="form-lable">桶槽名稱</label>
            </div>
            <div class="col-8 col-lg-4 mb-2">
                <input v-model="searchInfo.name" class="form-control" placeholder="Please enter" />
            </div>
            <div class="col-4 col-lg-2 mb-2">
                <label class="form-lable">所在地區</label>
            </div>
            <div class="col-8 col-lg-4 mb-2">
                <Multiselect v-model="searchInfo.area" placeholder="請選擇" :options="dropDownArea" track-by="id"
                    :show-labels="false" label="name" :searchable="true" :allow-empty="true">
                </Multiselect>
            </div>
            <div class="col-12  ">
                <button class="search-btn" @click="ChangePage(1, 'search')">
                    搜尋
                </button>
            </div>
        </div>
        <div class="row px-md-4   my-3">
            <div class="col-12  mb-3" style="display: flex;justify-content: flex-end;">
                <button class="upload-btn" @click="popForm_show('create', {})">
                    <font-awesome-icon :icon="['fas', 'plus']" class="mx-1" />
                    新增桶槽
                </button>
            </div>
        </div>
        <div class="row px-md-4 my-3">
            <div class="col-12 table-container">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th>桶槽名稱</th>
                            <th>所在地區</th>
                            <th>所屬單位</th>
                            <th>桶槽半徑(m)</th>
                            <th>桶槽高度(m)</th>
                            <th style="width:80px;"> </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="!searchTank || !searchTank.list || searchTank.list.length == 0">
                            <td colspan="9" class="td_noData">Nodata</td>
                        </tr>
                        <template v-if="searchTank && searchTank.list && searchTank.list.length != 0">
                            <tr v-for="(item, index) in  searchTank.list " :key="index">
                                <td>
                                    {{ item.name }}
                                </td>
                                <td>
                                    {{ item.area && item.area.name ? item.area.name : "" }}
                                </td>
                                <td>
                                    {{ item.area && item.area.name && item.area.department && item.area.department.name ?
                                        item.area.department.name : "-" }}
                                </td>
                                <td>
                                    {{ item.radius }}
                                </td>
                                <td>
                                    {{ item.height }}
                                </td>
                                <td class="">
                                    <img src="../../assets/img/edit.png" class="list-icon"
                                        @click="popForm_show('edit', item)">
                                    <img src="../../assets/img/delete.png" class="list-icon"
                                        @click="popForm_show('delete', item)">
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
            <div class="col-12">
                <PaginationVue v-bind:page-info="cm_pageInfo" v-bind:change-event="ChangePage"></PaginationVue>
            </div>
        </div>
    </div>
    <div class="pop-panel " v-if="popForm.show">
        <div class="pop-panel-container " v-bind:class="{ 'xl-panel': !(popForm.mode == 'delete') }">
            <div class="pop-panel-title" v-if="popForm.mode == 'upload'">上傳</div>
            <div class="pop-panel-title" v-if="popForm.mode == 'delete'">刪除</div>
            <div class="pop-panel-title" v-if="popForm.mode == 'edit'">編輯</div>
            <div class="pop-panel-title" v-if="popForm.mode == 'create'">新增</div>
            <span class="xmark" @click="popForm_clean()">
                ✕
            </span>
            <div class="pop-panel-inner">
                <div class="row" v-if="popForm.mode == 'delete' || popForm.mode == 'download'">
                    <div class="col-12 pop-panel-text" v-if="popForm.mode == 'delete'">
                        確定要刪除桶槽嗎？
                    </div>
                    <div class="pop-panel-btn">
                        <button class="btn btn-outline-primary" @click="popForm_clean()">No</button>
                        <button class="btn btn-primary " @click="popForm_save">Yes</button>
                    </div>
                </div>
                <div class="row" v-if="popForm.mode != 'delete' && popForm.mode != 'download'">

                    <div class="col-12 col-md-3 mb-0 mb-md-3">
                        <label class="form-lable require">上傳衛星定位圖</label>
                    </div>
                    <div class="col-12 col-md-9 mb-3" v-if="popForm.mode == 'create' || popForm.mode == 'edit'">
                        <button class="addFile-btn" onclick="document.getElementById('getFile').click()">
                            <font-awesome-icon :icon="['fas', 'plus']" class="me-2" />
                            選擇檔案
                        </button>
                        <div style="visibility: hidden;height: 0;">
                            <input type="file" id="getFile" accept="image/*" @change="fileChange" :key="popForm.formData">
                        </div>
                        <div class=" mt-3 fileInfoGroup">
                            <span v-if="popForm.formData_info" class="file-info">
                                <img src="../../assets/img/file.png"> {{ popForm.formData_info.name }} (
                                {{ Math.round(popForm.formData_info.size / 1000000 * 100) / 100 }}MB)
                            </span>
                            <img v-if="popForm.formData_info" :src="previewObjectURL()" class="filePreview" alt="">
                            <div class="errMsg"
                                v-if="popForm.formData_info && popForm.formData_info.size / 1000000 * 100 / 100 > file_max">
                                檔案超過2MB無法上傳
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-3 mb-0 mb-md-3">
                        <label class="form-lable require">桶槽名稱</label>
                    </div>
                    <div class="col-12 col-md-9 mb-3">
                        <input v-model="popForm.form.name" class="form-control" placeholder="Please enter" />
                    </div>
                    <div class="col-12 col-md-3 mb-0 mb-md-3">
                        <label class="form-lable require">地區</label>
                    </div>
                    <div class="col-12 col-md-9 mb-3">
                        <Multiselect v-model="popForm.form.area" placeholder="請選擇" :options="dropDownArea" track-by="id"
                            :show-labels="false" label="name" :searchable="true" :allow-empty="true">
                        </Multiselect>
                    </div>
                    <div class="col-12 col-md-3 mb-0 mb-md-3">
                        <label class="form-lable require">桶槽高度</label>
                    </div>
                    <div class="col-12 col-md-9 mb-3">
                        <input v-model="popForm.form.height" class="form-control" placeholder="Please enter"
                            type="number" />
                    </div>
                    <div class="col-12 col-md-3 mb-0 mb-md-3">
                        <label class="form-lable require">桶槽半徑</label>
                    </div>
                    <div class="col-12 col-md-9 mb-3">
                        <input v-model="popForm.form.radius" class="form-control" placeholder="Please enter"
                            type="number" />
                    </div>
                    <div class="col-12 col-md-3 mb-0 mb-md-3">
                        <label class="form-lable require">桶槽內容物</label>
                    </div>
                    <div class="col-12 col-md-9 mb-3">
                        <textarea v-model="popForm.form.contents" class="form-control" placeholder="Please enter" rows="2"
                            maxlength="1000"></textarea>
                    </div>
                    <div class="col-12 col-md-3 mb-0 mb-md-3">
                        <label class="form-lable  ">備註</label>
                    </div>
                    <div class="col-12 col-md-9 mb-3">
                        <textarea v-model="popForm.form.remark" class="form-control" placeholder="Please enter" rows="2"
                            maxlength="1000"></textarea>
                    </div>
                    <div class="errMsg">{{ popForm.msg }}</div>
                    <div class="cpp_btn">
                        <button class="btn btn-outline-primary" @click="popForm_clean()">Cancel</button>
                        <button class="btn btn-primary " @click="popForm_save">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PaginationVue from '../../components/layout/Pagination.vue'
import { mapState, mapActions, mapGetters } from 'vuex'
export default {
    searchUserList: {},
    name: 'TankManagement',
    components: {
        PaginationVue,
    },
    computed: {
        ...mapState(
            {
                dropDownTank: state => state.tank.dropDown,
                dropDownArea: state => state.area.dropDown,
                searchTank: state => state.tank.search,
                createTank: state => state.tank.create,
                deleteTank: state => state.tank.delete,
                updateTank: state => state.tank.update,
            }
        ),
        ...mapGetters(['CheckPermissions'])
    },
    data() {
        return {
            file_max: 2,
            tableModule: {
                searchHistory: null,
                searchCache: null,
                sort_order: "desc",
                sort_field: "id"
            },
            searchInfo: {
                name: null,
                checkedType: [],
                dateStart: null,
                dateEnd: null,
            },
            areaId: "",
            cm_pageInfo: {
                totalPage: 0,
                nowPage: 0,
            },
            popForm: {
                mode: "",
                show: false,
                isStart: false,
                isStarted: false,
                timer: null,
                itemData: {},
                formData: new FormData(),
                formData_info: null,
                form: {
                    title: null,
                    content: null,
                    is_show: ['is_show'],
                    order: 0
                },
                msg: ""
            }
        };
    },
    mounted() {
        this.ChangePage(1)
        this.GetDropDownTank({
            "page": 1,
            "count": 100,
            "sort_field": "id",
            "sort_order": "desc",
            "is_output": 0
        })
        this.GetDropDownArea(
            {
                "page": 1,
                "count": 1000,
                "sort_field": "id",
                "sort_order": "desc",
                "search": {},
                "is_dropdown": 1,
                "is_output": 0
            }
        );
    },
    watch: {
        searchTank: function (val) {
            this.cm_pageInfo = {
                totalPage: val.total_page,
                nowPage: val.page,
            }
            this.tableModule.searchHistory = this.tableModule.searchCache;
        },
        createTank(val) {
            if (val.data.header.message == "Success") {
                this.popForm_clean();
                this.GetSearchTank({
                    "page": 1,
                    "count": 10,
                    "sort_field": "id",
                    "sort_order": "desc",
                    "is_output": 0
                })
                this.GetDropDownTank({
                    "page": 1,
                    "count": 100,
                    "sort_field": "id",
                    "sort_order": "desc",
                    "is_output": 0
                })
            } else {
                this.popForm.msg = val.data.header.message;
                console.log(val.data)
                for (var i in val.data.data) {
                    this.popForm.msg = val.data.data[i][0];
                }
            }
        },
        updateTank(val) {
            if (val.data.header.message == "Success") {
                this.popForm_clean();
                this.GetSearchTank({
                    "page": 1,
                    "count": 10,
                    "sort_field": "id",
                    "sort_order": "desc",
                    "is_output": 0
                })
                this.GetDropDownTank({
                    "page": 1,
                    "count": 100,
                    "sort_field": "id",
                    "sort_order": "desc",
                    "is_output": 0
                })
            } else {
                this.popForm.msg = val.data.header.message;
                for (var i in val.data.data) {
                    this.popForm.msg = val.data.data[i][0];
                }
            }
        },
        deleteTank(val) {
            if (val.data.header.message == "Success") {
                this.popForm_clean();
                this.ChangePage(1);
                this.GetDropDownTank({
                    "page": 1,
                    "count": 100,
                    "sort_field": "id",
                    "sort_order": "desc",
                    "is_output": 0
                })
            } else {
                this.popForm.msg = val.data.header.message;
                for (var i in val.data.data) {
                    this.popForm.msg = val.data.data[i][0];
                }
            }
        }
    },
    methods: {
        ...mapActions([
            'GetDropDownArea',
            'GetDropDownTank',
            'GetSearchTank',
            'GetCreateTank',
            'GetDeleteTank',
            'GetUpdateTank'
        ]),
        popForm_show(_mode, _item) {
            this.popForm.show = true;
            this.popForm.mode = _mode;
            this.popForm.itemData = _item;

            if (this.popForm.mode == "edit") {
                this.popForm.formData = new FormData();
                var file = this.dataURLtoFile('data:image/jpeg;base64,' + _item.image, "已上傳檔案");
                this.popForm.formData.append("image", file);
                console.log('file', file)
                this.popForm.formData_info = file;
                this.popForm.form = {
                    name: _item.name,
                    id: _item.id,
                    contents: _item.contents,
                    height: _item.height,
                    radius: _item.radius,
                    order: _item.order,
                    area: this.getAreaItem(_item.area.id),
                    remark: _item.remark
                }
            }
        },
        popForm_save() {
            this.popForm.msg = "";
            if (this.popForm.mode == "delete") {
                this.GetDeleteTank({
                    "id": this.popForm.itemData.id
                });
                return;
            }
            if (!this.popForm.formData_info) {
                this.popForm.msg = "上傳衛星定位圖";
                return;
            }
            if (!this.popForm.form.name) {
                this.popForm.msg = "桶槽名稱 為必填欄位";
                return;
            }
            if (!this.popForm.form.area) {
                this.popForm.msg = "地區 為必填欄位";
                return;
            }
            if (!this.popForm.form.height) {
                this.popForm.msg = "桶槽高度 為必填欄位";
                return;
            }
            if (!this.popForm.form.radius) {
                this.popForm.msg = "桶槽半徑 為必填欄位";
                return;
            }
            if (!this.popForm.form.contents) {
                this.popForm.msg = "桶槽內容物 為必填欄位";
                return;
            }
            // if (!this.popForm.form.remark) {
            //     this.popForm.msg = "備註 為必填欄位";
            //     return;
            // }

            this.popForm.formData.append("name", this.popForm.form.name)
            this.popForm.formData.append("height", this.popForm.form.height)
            this.popForm.formData.append("radius", this.popForm.form.radius)
            this.popForm.formData.append("contents", this.popForm.form.contents)
            this.popForm.formData.append("area_id", this.popForm.form.area.id)
            this.popForm.formData.append("remark", this.popForm.form.remark ? this.popForm.form.remark : "")
            this.popForm.formData.append("type_id", 1)

            if (this.popForm.mode == "create") {
                this.GetCreateTank(this.popForm.formData);
                return;
            }
            if (this.popForm.mode == "edit") {
                this.popForm.formData.append("id", this.popForm.form.id)
                console.log(this.popForm.form.id)
                //塞資料  
                this.GetUpdateTank(this.popForm.formData);
                return;
            }
        },
        popForm_clean() {
            this.popForm = {
                mode: "",
                show: false,
                isStart: false,
                isStarted: false,
                timer: null,
                itemData: {},
                formData: new FormData(),
                form: {
                    title: null,
                    content: null,
                    is_show: ['is_show'],
                    order: 0
                },
                msg: ""
            }
        },
        ChangePage(num, mode) {
            var payload = {};
            if (mode == 'search' || !this.tableModule.searchHistory) {
                payload = {
                    "page": num,
                    "count": 10,
                    "sort_field": "id",
                    "sort_order": "asc",
                    "is_output": 0,
                    "search": {
                    }
                }
                if (this.searchInfo.name) payload.search.name = [this.searchInfo.name];
                if (this.searchInfo.area) payload.search.area_id = [this.searchInfo.area.id];
                this.tableModule.searchCache = payload;
            } else {
                this.tableModule.searchCache = this.tableModule.searchHistory;
                this.tableModule.searchCache.page = num;
                this.tableModule.searchCache.sort_field = this.tableModule.sort_field
                this.tableModule.searchCache.sort_order = this.tableModule.sort_order
            }
            this.tableModule.searchCache.is_output = mode == 'download' ? 1 : 0;
            this.GetSearchTank(this.tableModule.searchCache);
        },
        copyEvent(id) {
            var str = document.getElementById(id + '_link');
            window.getSelection().selectAllChildren(str);
            document.execCommand("Copy")
        },
        updateTiny(val) {
            console.log('updateTiny')
            this.popForm.form.content = val;
        },
        dataURLtoFile(dataurl, filename) {
            var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, { type: mime });
        },
        fileChange(e) {
            this.popForm.formData = new FormData();
            // this.popForm.formData.append('photo', [e.target.files[0], e.target.files[0], e.target.files[0]]); //放進上傳的檔案
            this.popForm.formData.append('image', e.target.files[0]); //放進上傳的檔案
            this.popForm.formData_info = e.target.files[0];
            this.popForm.msg = null;

            if ((e.target.files[0].size / 1000000 * 100 / 100) >= this.file_max) {
                this.popForm.msg = '檔案超過2MB無法上傳';
                return
            }
        },
        previewObjectURL() {
            var objectURL = window.URL.createObjectURL(this.popForm.formData_info);
            return objectURL;
        },
        getAreaItem(id) {
            for (var i in this.dropDownArea) {
                if (this.dropDownArea[i].id == id) {
                    return this.dropDownArea[i]
                }
            } return null
        },

    }
}
</script>
 