<template>
	<div class="container ">
		<div class="col-sm-12 offset-md-3 col-md-6 offset-lg-4 col-lg-4 login-vue">
			<img class="login-logo" src="../../assets/img/logo.png">
			<!-- <form class="row "> -->
			<div class="input-group mb-4 mt-5">
				<span class="input-group-text igt-before" id="">
					<img src="../../assets/img/member.png" class="login-icon-member">
				</span>
				<input v-model="email" type="text" class="form-control" placeholder="請輸入帳號">
			</div>
			<div class="input-group mb-2 input-group-psw">
				<span class="input-group-text igt-before" id="">
					<img src="../../assets/img/psw.png" class="login-icon-psw">
					<img src="../../assets/img/show.png" class="login-icon-show" :class="{ 'hide': showPsw }"
						@click="showPsw = true">
					<img src="../../assets/img/unshow.png" class="login-icon-unshow" :class="{ 'hide': !showPsw }"
						@click="showPsw = false">
				</span>
				<input v-model="psw" v-bind:type="type_showPsw()" class="form-control" placeholder="請輸入密碼">
			</div>
			<div class="input-group mb-3 ">
				<div class="form-check ms-3">
					<input v-model="saveToken" class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
					<label class="form-check-label" for="flexCheckDefault">
						記住密碼
					</label>
				</div>
			</div>

			<div class="errMsg">{{ errorMsg }} </div>
			<div class="input-group mb-2 mt-2">
				<button @click="Login" class="btn btn-primary w-100 ">登入</button>
			</div>
			<!-- <div class="input-group mb-5 justify-content-center ">
				<button class="mb-5 login-forgot" >Forgot Password?</button>  
			</div>  -->
			<!-- </form>  -->
		</div>
	</div>
</template>
 
<script>
// import axios from 'axios';  
import { mapState } from 'vuex'
export default {
	name: 'Login',
	computed: {
		...mapState(
			{
				loginInfo: state => state.loginInfo,
			}
		),
	},
	watch: {
		loginInfo(val) {
			this.errorMsg = "";
			if (val.header.message != "Success") {
				this.errorMsg = val.header.message;
			}
		}
	},
	data() {
		return {
			showPsw: false,
			email: "",
			psw: "",
			saveToken: false,
			errorMsg: ""
		}
	},
	mounted() {
		var userInfo = JSON.parse(localStorage.getItem('userInfo'))

		if (userInfo) {
			this.$store.state.userInfo = userInfo;
			this.psw = userInfo.password;
			this.email = userInfo.email;
			// if(userInfo.token) this.$store.commit('SetLoginToken',userInfo.token);
		}
	},
	methods: {
		type_showPsw() {
			return this.showPsw ? 'text' : 'password';
		},
		Login() {
			this.$store.dispatch('GetLoginToken',
				{
					"email": this.email,
					"g-recaptcha-response": "03AL8dmw8UPIxvXeVaO1Gk1mvwAROgQf3uG8gNmxMXYGvqesoVib0ZF6IIP-5gTkihO6J0MohkA5sQRIEiYGdQUzTt_TdrESK0yx6NBOMzMmVeNzNbz7TpGFRHH7VH9pt9DmMSu204ZgiMnLsmEVEPJiWXEvEd-nNApuTZSGRNmZrXCxTwaGZFVkOppscg33JwdIZiDJf8KvWIC_PTbKvbDUQV0XYGt5pshEBw1pUlaL3DWshgMpldBCPaX4nilzCVkiWdL_mjlolFP1M1MYqR5lnMqZhZZ9KF9Qh2xYXdoKldU2vLxAw0H4wmgKTxmYQeRXPbtfy8ML2ErO1H3bZw3C9m4q0aL-dCCvUVpfgQ3f5c7DtH294GCzChtKv8ex43-gujL5YnAefrqZhp8LU8VfetX5w4b3S-7Vuq1KcIQ3nnyBmjIjKK8x1WQPj_01DKHYpoZ8AXkIXoIUqpE9MTplNyk9LfWbQ0OiNZtUOnWQb1mSrytDOX7ErBEzqEMrnrmFLfFbXE7L_qrr2_xcIWTPzWlI6qC0JpYp9nufCnXOuLyEKhNTmiewtDU614HZgZkxbiRUOJFCFfOOia_v7d8xY178Eu82KipmsF2gDVFLcRYJAy_f58kDMRQlBorGVZynICFIVB6WcnYT1IgsGByleGB-VBkBSj2DDvYI3PruYFcHC7D019wRZdjtkAzEqykIIK2mY5p82jUDYJI-Sk3PMOU3xlJeynabPVSwZ5feqXfEJMPFql7tKpgXWaduGVa5BpLfDk3_wgkob1PU2xl51-wfzko7glh7jsPP8o_TdMu6GQCJWAoeewMOy4JlOzJT5Pxo4S2zMmDkj9g1qOLdWEeaABzVh_DSfevXs59DHt1G1vOqzjtwMqtyNygE69mz_oWs-v1IYbBFCT4LBiU0Rk2VNBM8ho-OCltgxwqhygCevALxAsPBxnuJdP5FjLDVbNWzMTl25x6-Bk5UU0HcXCWA8wEWmAtjiPF18I6BQ8qu28e6Yr8VnwHiVh-jBJKWNvohUhTR8he5cUnZpsPSoltgExgvuS7ovAZq8po_8LQzTMtoGZEjsFou0rf3VKJz2u3K1OaiP4cIQFEm_Rhex-WS8BkxmM1HmcbbyYv1lyQKmYUkVM9HcbM9SQ_kokZKh9On1LhltOJ31sagPG1kReF3MM19Zj66OfbRNEZ6dtAGgWe5qZ6MEjcfv1OP6meVlTF18vDfWYy3-GuY-YKJ0wsi2qNDXSFJKQdf_kNptpb8HkH_9dhaFB3nVlyTQGRDDQTdl_6o1liuDG5I4rkGeOcm-qxdOY7Wg6zxBFq2mraWXaNYkPa_ZLltpolbvfwCz9p5QhxvBrK74d5Ck0P7AIQhhndYywViOmzeE8fFaHuf5GNbIg-jB89o3WC_sLvFLpUKZy5y979xyVp6f28IYohQTd0u3hzlOkBwCYCaezvZP0X_ybHF-4O_-Yh3-fo_wRf2sQCAZGz7z5Vb1FgntHD2bK2fLgqg",
					"password": this.psw, "saveToken": this.saveToken
				}
			);
		}
	}
}
</script>
