<template>
    <nav aria-label="Page navigation example">
        <ul class="pagination justify-content-end">
            <li class="page-item" @click="if (pageInfo.nowPage != 1) changeEvent(pageInfo.nowPage - 1);"
                v-bind:class="{ 'disabled': pageInfo.nowPage == 1 }">
                <a class="page-link" tabindex="-1" aria-disabled="true">上一頁</a>
            </li>
            <li class="page-item" v-for="(value, index) in getNumBtnArr()" :key="index"
                @click="if (value != '...' && value != pageInfo.nowPage) changeEvent(value);"
                v-bind:class="{ 'disabled': value == '...', 'active': value == pageInfo.nowPage }">
                <a class="page-link">{{ value }}</a>
            </li>
            <li class="page-item"
                @click="if (pageInfo.nowPage != pageInfo.totalPage && pageInfo.totalPage != 0) changeEvent(pageInfo.nowPage + 1);"
                v-bind:class="{ 'disabled': pageInfo.nowPage == pageInfo.totalPage || pageInfo.totalPage == 0 }"><a
                    class="page-link">下一頁</a></li>
        </ul>
    </nav>
</template> 

<script>
export default {
    name: 'AccountManagement',
    components: {
    },
    props: ['pageInfo', 'changeEvent'],
    data() {
        return {
            numBtnSize: 5,
        }
    },
    methods: {
        getNumBtnArr() {
            var _total = this.pageInfo.totalPage,
                _now = this.pageInfo.nowPage,
                _size = this.numBtnSize,
                numBtnArr = [];

            if (_total != 0)
                numBtnArr.push(1, _total);

            if (_total <= _size) {
                //全部顯示
                for (var i = 1; i <= _total; i++) numBtnArr.push(i);
            } else if (_now <= parseInt(_size / 2)) {
                //前方直接補滿
                for (var j = 0; j < _size; j++) numBtnArr.push(1 + j);
            } else if (_now >= (_total - parseInt(_size / 2))) {
                //後方直接補滿 
                for (var k = 0; k < _size; k++) numBtnArr.push(_total - k);
            } else {
                //放中間
                for (var l = 0; l <= parseInt(_size / 2); l++) {
                    numBtnArr.push(_now - l, _now + l);
                    if ((_now - l - 2) == 1) numBtnArr.push(_now - l - 1);
                    if ((_now + l + 2) == _total) numBtnArr.push(_now + l + 1);
                }
            }

            numBtnArr.sort(function (a, b) { return a - b; });
            numBtnArr = numBtnArr.filter((item, index) => numBtnArr.indexOf(item) === index);

            var dotPosition = [];
            numBtnArr.forEach(function (value, index, array) {
                if (array.length - 1 != index && (array[index] + 1) != (array[index + 1])) {
                    dotPosition.push(index + 1)
                }
            });
            if (dotPosition[0]) numBtnArr.splice(dotPosition[0], 0, "...");
            if (dotPosition[1]) numBtnArr.splice(dotPosition[1] + 1, 0, "...");

            return numBtnArr;
        }
    }
}

</script>
