<template>
    <div class="col-md-12">
        <img v-if="measureData.list && measureData.list[0].tank && measureData.list[0].tank.id == 2"
            src="https://display-backend.droxotech.com/storage/messageImage_1691646492626.jpg" style="    position: fixed;
    left: 10px;
    top: 180px;
    z-index: 999;
    width: 217px;">
        <img v-if="measureData.list && measureData.list[0].tank && measureData.list[0].tank.id == 3"
            src="https://display-backend.droxotech.com/storage/messageImage_1691732250059.jpg" style="    position: fixed;
    left: 10px;
    top: 180px;
    z-index: 999;
    width: 217px;">
        <div class="canvas-wrap">
            <canvas width="1720" height="1080" id="three"></canvas>
        </div>
        <div class="row mt-3">
            <div class="col-md-12">
                <div class="tank-info">
                    <b>色層篩選：</b>
                </div>
                <div style="display: flex;align-items: center;gap: 30px;margin-top: 30px;">
                    <Slider style="width: 100%" :min="measureData.list[0].tank.id == 2 ? 3 : 4"
                        :max="measureData.list[0].tank.id == 2 ? 10 : 13" :logarithmic="true"
                        v-model="showDetail.color_range" />
                    <button class="search-btn" @click="dot_redraw()" style="display: inline-block;">
                        確認
                    </button>
                </div>
            </div>
            <div class="col-md-4">
                <div class="tank-info">
                    <b>桶槽名稱：</b>
                    <div>{{ measureData.list[0].tank.name }}</div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="tank-info">
                    <b>桶槽半徑(m)：</b>
                    <div>{{ measureData.list[0].tank.radius }}</div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="tank-info">
                    <b>桶槽高度(m)：</b>
                    <div>{{ measureData.list[0].tank.height }}</div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="tank-info">
                    <b>測量數量：</b>
                    <div>{{ tankInfo.measureNum }}</div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="tank-info">
                    <b>顯示模式：</b>
                    <!-- <div v-if="measureData.list[0].tank.tank_type && measureData.list[0].tank.tank_type.name">{{
                        measureData.list[0].tank.tank_type.name }}</div> -->
                    <div>壁厚</div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="tank-info">
                    <b>場域位置：</b>
                    <div v-if="measureData.list[0].tank.area && measureData.list[0].tank.area.name">{{
                        measureData.list[0].tank.area.name }}</div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="tank-info">
                    <b>測定報告：</b>
                    <div
                        v-if="measureData.list[0].tank.area && measureData.list[0].tank.area.name && measureData.list[0].tank.id == 2">
                        <a target="_blank"
                            href="https://display-backend.droxotech.com/storage/貼壁式攀附智能車測試報告20230703-奇美實業.pdf"
                            style="color: #19325f;">20230703_壁厚檢測_T06-02</a>
                    </div>
                    <div
                        v-if="measureData.list[0].tank.area && measureData.list[0].tank.area.name && measureData.list[0].tank.id == 3">
                        <a target="_blank"
                            href="https://display-backend.droxotech.com/storage/貼壁式攀附智能車測試報告20230801-李長榮化學工業股份有限公司.pdf"
                            style="color: #19325f;">20230801_壁厚檢測_T591</a>
                    </div>
                </div>
            </div>
            <div class="col-md-8">
                <button class="search-btn" @click="stop = true, closeView()">
                    回上一頁
                </button>
            </div>
        </div>
    </div>
    <div class="hoverTip">
        <span v-if="showDetail.hover_show && showDetail.info && showDetail.info.id"> 測量點ID : {{
            showDetail.info.index
        }}</span>
        <span v-if="showDetail.hover_show && showDetail.info && showDetail.info.id"> 測量值 : {{
            (Math.round(parseFloat(showDetail.info.coating_thickness) * 100) / 100).toFixed(2)
        }}</span>
    </div>
    <div class="pop-panel" v-if="showDetail.show && showDetail.info && showDetail.info.id">
        <div class="pop-panel-container py-4" style="width: 700px;">
            <div class="pop-panel-title" style="text-align: center; color: #159BD7;">桶槽資訊</div>
            <div class="pop-panel-inner"><span class="xmark" @click=" hideDetail()">
                    ✕
                </span>
                <div class="row">
                    <div class="col-12 ">
                        <img v-if="displayBase64Img && displayBase64Img.data && displayBase64Img.data.base64Image"
                            class="mb-2" v-bind:src="'data:image/jpeg;base64,' + displayBase64Img.data.base64Image"
                            style="width: 100%;">
                        <!-- <span
                            v-if="displayBase64Img && displayBase64Img.header && displayBase64Img.header && displayBase64Img.header.message != 'Success'"
                            class="errMsg mt-2">{{
                                displayBase64Img.header.message }}</span> -->
                        <div class="loaderPanel" v-if="!displayBase64Img">
                            <span class="loader"></span>
                        </div>
                    </div>
                    <div class="col-4 mt-3">
                        <div class="tank-info">
                            <b>測量點ID :</b>
                            <div>{{ showDetail.info.index }}</div>
                        </div>
                        <div class="tank-info">
                            <b>方位 :</b>
                            <div> {{ showDetail.info.degree }}</div>
                        </div>
                    </div>
                    <div class="col-4  mt-3">
                        <div class="tank-info">
                            <b>測量位置 :</b>
                            <div> x : {{ (Math.round(parseFloat(showDetail.info.x) * 100) / 100).toFixed(2) }}</div>
                            <div> y : {{ (Math.round(parseFloat(showDetail.info.y) * 100) / 100).toFixed(2) }}</div>
                            <div> z : {{ (Math.round(parseFloat(showDetail.info.z) * 100) / 100).toFixed(2) }}</div>
                        </div>
                    </div>
                    <div class="col-4  mt-3">
                        <div class="tank-info">
                            <b>測量值(mm) :</b>
                            <div> {{
                                (Math.round(parseFloat(showDetail.info.coating_thickness) * 100) / 100).toFixed(2) }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import Slider from '@vueform/slider'
import '@vueform/slider/themes/default.css'
// import { Text } from 'troika-three-text'

export default {
    searchUserList: {},
    name: 'HcpUpload',
    components: {
        Slider
    },
    props: ["measureData", "closeView"],
    data() {
        return {
            number: 10,
            msg: "",
            stop: false,
            showDetail: {
                isChange: false,
                show: false,
                info: null,
                hover_show: true,
                coating_thickness_max: 0,
                coating_thickness_min: 0,
                show_range: [0, 0],
                color_range: [0, 0],
            },
            searchInfo: {
                org: null,
                area: null,
                tank: null,
            },
            tankInfo: {
                radiusTop: 10.98,
                radiusBottom: 10.98,
                height: 11,
                radius: 10,
                segments: 45,
                measureNum: 0,
            },
        };
    },
    watch: {
        'searchInfo.org': function (val) {
            if (val != null) {
                this.GetDropDownAreaList({
                    organization_id: [val.id]
                });
            }
            this.searchInfo.area = null
            this.searchInfo.tank = null
        },
        'searchInfo.area': function (val) {
            if (val != null) {
                this.GetDropDownTankList({
                    area_id: [val.id]
                });
            }
            this.searchInfo.tank = null
        }
    },
    mounted() {
        // this.GetDropDownOrgList({});

        this.initThree()
    },
    computed: {
        ...mapState(
            {
                // dropDownOrgList: state => state.dropDown.org,
                // dropDownAreaList: state => state.dropDown.area,
                // dropDownTankList: state => state.dropDown.tank,
                // searchDisplayList: state => state.search.display,
                displayBase64Img: state => state.displayBase64Img,
            }
        ),
    },
    methods: {
        initThree() {
            var self = this;

            const scene = new THREE.Scene()
            scene.background = new THREE.Color('#eee')
            const canvas = document.querySelector('#three')
            const renderer = new THREE.WebGLRenderer({ canvas, antialias: true })
            const camera = new THREE.PerspectiveCamera(
                20,
                window.innerWidth / window.innerHeight,
                1,
                10000
            )
            camera.position.setZ(40);
            camera.position.setY(20);
            camera.position.setX(70);
            scene.position.setY(-7);

            var raycaster = new THREE.Raycaster();
            var mouse = new THREE.Vector2();

            function onMouseMove(event) {

                mouse.x = ((event.clientX - canvas.getBoundingClientRect().x) / canvas.getBoundingClientRect().width) * 2 - 1;
                mouse.y = - ((event.clientY - canvas.getBoundingClientRect().y) / canvas.getBoundingClientRect().height) * 2 + 1;
                raycaster.setFromCamera(mouse, camera);

                //取得 raycaster 射線和所有物件相交的陣列集合
                var intersects = raycaster.intersectObjects(scene.children);
                //計算後，將所有有相交的物件的顏色改變為紅色，如果只需要將第一個觸發事件，那就陣列的第一個物件改變顏色即可 
                if (intersects.length != 0) for (var i = 0; i < 1; i++) {
                    for (j = 0; j < scene.children.length; j++) {
                        if (scene.children[j].geometry && scene.children[j].geometry.type == 'SphereGeometry') {
                            scene.children[j].material.color.set(scene.children[j].deep);
                            // scene.children[j].geometry.scale(1 / 1.5, 1 / 1.5, 1 / 1.5);  
                            // scene.children[j].geometry.scale(1, 1, 1);
                            // console.log("move")
                        }
                    }

                    if (i == 0 && intersects[i].object.geometry.type == 'SphereGeometry') {
                        intersects[i].object.material.color.set(0x00ffff);
                        // intersects[i].object.geometry.scale(1.5, 1.5, 1.5); 
                        self.showDetail.info = intersects[i].object.measureInfo;
                        self.showDetail.hover_show = true;
                        var _hoverEle = document.getElementsByClassName("hoverTip");
                        _hoverEle[0].style.left = event.clientX + 5 + 'px';
                        _hoverEle[0].style.top = event.clientY + 5 + 'px';
                        return;
                    }
                }
                self.showDetail.info = null;
            }

            function onMouseUp(event) {
                for (i = 0; i < scene.children.length; i++) {
                    if (scene.children[i].geometry && scene.children[i].geometry.type == 'SphereGeometry') {
                        scene.children[i].material.color.set(scene.children[i].deep);
                    }
                }
                //透過滑鼠點選的位置計算出 raycaster 所需要的點的位置，以螢幕中心為原點，值的範圍為-1到1. 
                mouse.x = ((event.clientX - canvas.getBoundingClientRect().x) / canvas.getBoundingClientRect().width) * 2 - 1;
                mouse.y = - ((event.clientY - canvas.getBoundingClientRect().y) / canvas.getBoundingClientRect().height) * 2 + 1;

                raycaster.setFromCamera(mouse, camera);
                //取得 raycaster 射線和所有物件相交的陣列集合
                var intersects = raycaster.intersectObjects(scene.children);
                //計算後，將所有有相交的物件的顏色改變為紅色，如果只需要將第一個觸發事件，那就陣列的第一個物件改變顏色即可 
                if (intersects.length != 0) for (var i = 0; i < 1; i++) {
                    if (i == 0 && intersects[i].object.geometry.type == 'SphereGeometry') {
                        intersects[i].object.material.color.set(0x00ff00);
                        self.showDetail.info = intersects[i].object.measureInfo;
                        self.GetDisplayBase64Img(intersects[i].object.measureInfo.image)
                        self.showDetail.show = true;
                    }
                }
            }

            /*----光源----*/
            const pointLight = new THREE.PointLight(0xfffffff);
            const ambientLight = new THREE.AmbientLight(0xfffffff);
            pointLight.position.set(20, 5, 5);
            // scene.add(pointLight, ambientLight);

            /*----光源輔助----*/
            //const lightHelper = new THREE.PointLightHelper(pointLight);

            /*----網格輔助----*/
            // const gridHelper = new THREE.GridHelper(500, 20);
            // scene.add(lightHelper, gridHelper);

            /*----滑鼠控制----*/
            const controls = new OrbitControls(camera, renderer.domElement);

            scene.add(pointLight, ambientLight);
            // scene.add(gridHelper);

            /*----PolarGridHelper----*/
            const radius = 50;
            const radials = 16;
            const circles = 5;
            const divisions = 64;
            const helper = new THREE.PolarGridHelper(radius, radials, circles, divisions, "#dadada", "#9c9c9c");
            scene.add(helper);


            // const myText = new Text()
            // scene.add(myText)

            // // Set properties to configure:
            // myText.text = 'Hello world!'
            // myText.fontSize = 0.2
            // myText.position.z = 0
            // myText.color = 0x9966FF

            // // Update the rendering:
            // myText.sync()

            /*----重新渲染桶槽----*/
            var t_id = this.measureData.list[0].tank_id;
            if (t_id == 1) {
                var tankGeometry = this.createTank();
                var circleTop = this.createCircle(this.tankInfo.radiusTop, this.tankInfo.segments, { x: 0, y: this.tankInfo.height, z: 0 });
                var circleBottom = this.createCircle(this.tankInfo.radiusBottom, this.tankInfo.segments, { x: 0, y: 0, z: 0 });
                scene.add(tankGeometry, circleTop, circleBottom);
            } else if (t_id == 2) {
                var tankGeometry2 = this.createTank2();
                var tankGeometry22 = this.createTank22();
                var circleTop2 = this.createCircle(15, this.tankInfo.segments, { x: 0, y: 2.5 + 5.7 + 4.4, z: 0 });
                var circleBottom2 = this.createCircle(15, this.tankInfo.segments, { x: 0, y: 5.7 + 4.4, z: 0 });
                var circleBottom22 = this.createCircle(6.3, this.tankInfo.segments, { x: 0, y: 4.4, z: 0 });
                scene.add(tankGeometry22, tankGeometry2, circleTop2, circleBottom2, circleBottom22);

                /*----arrow----*/
                const arrow = new THREE.ConeGeometry(1, 3.5, 32);
                const arrowMaterial = new THREE.MeshBasicMaterial({ color: "red" });
                const cone = new THREE.Mesh(arrow, arrowMaterial);
                let c = new THREE.Cylindrical().setFromCartesianCoords(11, 11.9, 0);
                let c_new = new THREE.Cylindrical(c.radius, c.theta - (105 / 360) * 2 * Math.PI, c.y)
                let v = new THREE.Vector3().setFromCylindrical(c_new);
                cone.position.setZ(v.z);
                cone.position.setY(v.y + 0.5);
                cone.position.setX(v.x);
                cone.rotateX(2 * Math.PI * (90 / 360));
                cone.rotateZ(2 * Math.PI * (15 / 360))
                cone.rotateY((c.theta - (105 / 360) * 2 * Math.PI) - Math.PI);
                scene.add(cone);

                const arrowBar = new THREE.CylinderGeometry(0.06, 0.06, 7, 32);
                const arrowBarMaterial = new THREE.MeshBasicMaterial({ color: "red" });
                const cylinder = new THREE.Mesh(arrowBar, arrowBarMaterial); scene.add(cylinder);
                c = new THREE.Cylindrical().setFromCartesianCoords(7, 12.6, 0);
                c_new = new THREE.Cylindrical(c.radius, c.theta - (105 / 360) * 2 * Math.PI, c.y)
                v = new THREE.Vector3().setFromCylindrical(c_new);
                cylinder.position.setZ(v.z);
                cylinder.position.setY(v.y);
                cylinder.position.setX(v.x);
                cylinder.rotateX(2 * Math.PI * (90 / 360));
                cylinder.rotateZ(2 * Math.PI * (15 / 360))
                cylinder.rotateY((c.theta - (105 / 360) * 2 * Math.PI) - Math.PI);
                // const arrow = new THREE.ConeGeometry(1.5, 3.5, 3);
                // const arrowMaterial = new THREE.MeshBasicMaterial({ color: "red" });
                // const cone = new THREE.Mesh(arrow, arrowMaterial);
                // cone.position.setZ(0);
                // cone.position.setY(11.9);
                // cone.position.setX(11);
                // cone.rotateZ(-2 * Math.PI * (90 / 360));
                // cone.rotateY(Math.PI * (60 / 360));
                // scene.add(cone);

                // const arrowBar = new THREE.CylinderGeometry(0.06, 0.06, 7, 32);
                // const arrowBarMaterial = new THREE.MeshBasicMaterial({ color: "red" });
                // const cylinder = new THREE.Mesh(arrowBar, arrowBarMaterial); scene.add(cylinder);
                // cylinder.position.setZ(0);
                // cylinder.position.setY(12.6);
                // cylinder.position.setX(7);
                // cylinder.rotateZ(-2 * Math.PI * (90 / 360));
                // cylinder.rotateY(Math.PI * (60 / 360));

            } else if (t_id == 3) {
                var tankGeometry3 = this.createTank3();
                var circleTop3 = this.createCircle(5.68, this.tankInfo.segments, { x: 0, y: 14.5, z: 0 });
                var circleBottom3 = this.createCircle(5.68, this.tankInfo.segments, { x: 0, y: 0.52, z: 0 });
                scene.add(tankGeometry3, circleTop3, circleBottom3);

                var tankGeometry32 = this.createTank32();
                var circleTop32 = this.createCircle(5.68 + 0.3, this.tankInfo.segments, { x: 0, y: 0.5, z: 0 });
                var circleBottom32 = this.createCircle(5.68 + 0.3, this.tankInfo.segments, { x: 0, y: 0, z: 0 });
                scene.add(tankGeometry32, circleTop32, circleBottom32);

                /*----arrow----*/
                const arrow = new THREE.ConeGeometry(0.4, 0.8, 3);
                const arrowMaterial = new THREE.MeshBasicMaterial({ color: "red" });
                const cone = new THREE.Mesh(arrow, arrowMaterial);
                cone.position.setZ(0);
                cone.position.setY(14.55);
                cone.position.setX(4.1);
                cone.rotateZ(-2 * Math.PI * (90 / 360));
                cone.rotateY(Math.PI * (60 / 360));
                scene.add(cone);

                const arrowBar = new THREE.CylinderGeometry(0.03, 0.02, 3, 32);
                const arrowBarMaterial = new THREE.MeshBasicMaterial({ color: "red" });
                const cylinder = new THREE.Mesh(arrowBar, arrowBarMaterial); scene.add(cylinder);
                cylinder.position.setZ(0);
                cylinder.position.setY(14.56);
                cylinder.position.setX(2.9);
                cylinder.rotateZ(-2 * Math.PI * (90 / 360));
                cylinder.rotateY(Math.PI * (60 / 360));

            }

            var count = 0;
            for (var _i in this.measureData.list) {
                for (var _j in this.measureData.list[_i].tank_measurement_details) {
                    _j;
                    if (_j == 0) {
                        self.showDetail.coating_thickness_max = this.measureData.list[_i].tank_measurement_details[_j].coating_thickness;
                        self.showDetail.coating_thickness_min = this.measureData.list[_i].tank_measurement_details[_j].coating_thickness;
                    }
                    if (self.showDetail.coating_thickness_max < this.measureData.list[_i].tank_measurement_details[_j].coating_thickness)
                        self.showDetail.coating_thickness_max = this.measureData.list[_i].tank_measurement_details[_j].coating_thickness;
                    if (self.showDetail.coating_thickness_min > this.measureData.list[_i].tank_measurement_details[_j].coating_thickness)
                        self.showDetail.coating_thickness_min = this.measureData.list[_i].tank_measurement_details[_j].coating_thickness;
                    console.log(this.measureData.list[_i].tank_measurement_details[_j].coating_thickness)
                    count++;
                }
            }
            this.showDetail.show_range = [this.showDetail.coating_thickness_min, this.showDetail.coating_thickness_max];
            this.showDetail.color_range = this.measureData.list[0].tank.id == 2 ? [4, 9] : [3, 14];
            // console.log('color_range', this.showDetail.color_range)
            this.tankInfo.measureNum = count;

            let _index = 1;
            for (var i in this.measureData.list) {
                for (var j in this.measureData.list[i].tank_measurement_details) {
                    const geometry = new THREE.SphereGeometry(0.2);
                    var _hsl = ((this.measureData.list[i].tank_measurement_details[j].coating_thickness - self.showDetail.color_range[0]) / (self.showDetail.color_range[1] - self.showDetail.color_range[0]) * 99.9) * 0.7 + 25

                    const material = new THREE.MeshStandardMaterial({ color: 'hsl(' + _hsl + ', 100%, 50%)' });
                    var dot = new THREE.Mesh(geometry, material);
                    dot.position.set(
                        this.measureData.list[i].tank_measurement_details[j].x,
                        this.measureData.list[i].tank_measurement_details[j].y,
                        this.measureData.list[i].tank_measurement_details[j].z - (t_id == 1 ? this.tankInfo.radiusTop : 0));
                    dot.deep = 'hsl(' + _hsl + ', 100%, 50%)';

                    dot.measureInfo = this.measureData.list[i].tank_measurement_details[j];
                    dot.measureInfo.index = _index++;
                    dot.coating_thickness = this.measureData.list[i].tank_measurement_details[j].coating_thickness;

                    scene.add(dot);
                }
            }

            function animate() {
                //if isChange數值有更動 dotReload
                renderer.render(scene, camera)
                if (self.showDetail.isChange) {
                    self.showDetail.isChange = false;

                    var _min = self.showDetail.color_range[0], _max = self.showDetail.color_range[1];
                    for (i = 0; i < scene.children.length; i++) {
                        var _hsl = '#a3a3a3'
                        if (scene.children[i].geometry && scene.children[i].geometry.type == 'SphereGeometry') {
                            if (scene.children[i].coating_thickness >= _min && scene.children[i].coating_thickness <= _max) {
                                _hsl = 'hsl(' + (((scene.children[i].coating_thickness - _min) / (_max - _min) * 99.9) * 0.7 + 25) + ', 100%, 50%)';
                            }
                            scene.children[i].material.color.set(_hsl);
                            scene.children[i].deep = _hsl;
                        }
                    }
                }

                if (!self.stop) requestAnimationFrame(animate);
                controls.update()
            }
            canvas.addEventListener('mousedown', onMouseUp, false);
            canvas.addEventListener('mousemove', onMouseMove, false);
            animate();
        },
        clearScene(scene) {
            var i;
            for (i = 0; i < scene.children.length; i++) {
                var obj = scene.children[i];
                scene.remove(obj);
            }
        },
        createTank() {
            /*----幾何----*/
            //CylinderGeometry(radiusTop  , radiusBottom  , height  , radialSegments : 圓切邊, heightSegments : 高切邊, openEnded : 空心 )
            var geometry = new THREE.CylinderGeometry(this.tankInfo.radiusTop - 0.2, this.tankInfo.radiusBottom - 0.2, this.tankInfo.height, this.tankInfo.segments, 20);
            var material = new THREE.MeshBasicMaterial({ color: 0xffffff });
            var tank = new THREE.Mesh(geometry, material);
            tank.position.setY(this.tankInfo.height / 2);
            tank.position.set(0, this.tankInfo.height / 2, 0);
            return tank;
        },
        createTank2() {
            /*----幾何----*/
            //CylinderGeometry(radiusTop  , radiusBottom  , height  , radialSegments : 圓切邊, heightSegments : 高切邊, openEnded : 空心 )
            var geometry = new THREE.CylinderGeometry(15, 15, 2.5, this.tankInfo.segments, 20);
            var material = new THREE.MeshBasicMaterial({ color: 0xffffff });
            var tank = new THREE.Mesh(geometry, material);
            tank.position.set(0, 2.5 / 2 + 5.7 + 4.4, 0);
            return tank;
        },
        createTank22() {
            /*----幾何----*/
            //CylinderGeometry(radiusTop  , radiusBottom  , height  , radialSegments : 圓切邊, heightSegments : 高切邊, openEnded : 空心 )
            var geometry = new THREE.CylinderGeometry(15, 6.38, 5.7, this.tankInfo.segments, 20);
            var material = new THREE.MeshBasicMaterial({ color: 0xffffff });
            var tank = new THREE.Mesh(geometry, material);
            tank.position.set(0, 5.7 / 2 + 4.4, 0);
            return tank;
        },
        createTank32() {
            /*----幾何----*/
            //CylinderGeometry(radiusTop  , radiusBottom  , height  , radialSegments : 圓切邊, heightSegments : 高切邊, openEnded : 空心 )
            var geometry = new THREE.CylinderGeometry(5.68 + 0.3, 5.68 + 0.3, 0.5, this.tankInfo.segments, 20);
            var material = new THREE.MeshBasicMaterial({ color: 0xffffff });
            var tank = new THREE.Mesh(geometry, material);
            tank.position.setY(0.5 / 2);
            tank.position.set(0, 0.5 / 2, 0);
            return tank;
        },
        createTank3() {
            /*----幾何----*/
            //CylinderGeometry(radiusTop  , radiusBottom  , height  , radialSegments : 圓切邊, heightSegments : 高切邊, openEnded : 空心 )
            var geometry = new THREE.CylinderGeometry(5.68, 5.68, 14.5, this.tankInfo.segments, 20);
            var material = new THREE.MeshBasicMaterial({ color: 0xffffff });
            var tank = new THREE.Mesh(geometry, material);
            tank.position.setY(-14.5 / 2);
            tank.position.set(0, 14.5 / 2, 0);
            return tank;
        },
        createCircle(radius, segments, position) {
            const circleGeometry = new THREE.CircleGeometry(radius, segments);

            // Remove center vertex
            const itemSize = 3;
            circleGeometry.setAttribute('position',
                new THREE.BufferAttribute(
                    circleGeometry.attributes.position.array.slice(itemSize,
                        circleGeometry.attributes.position.array.length - itemSize
                    ), itemSize
                )
            );
            circleGeometry.index = null;
            var _c = new THREE.LineLoop(circleGeometry, new THREE.LineBasicMaterial({ color: 'black' }));

            _c.rotateX(2 * Math.PI * (90 / 360));
            _c.position.setX(position.x)
            _c.position.setY(position.y)
            _c.position.setZ(position.z)
            return _c;
        },
        ...mapActions([
            'GetDropDownOrgList',
            'GetDropDownAreaList',
            'GetDropDownTankList',
            'GetSearchDisplayList',
            'GetDisplayBase64Img'
        ]),
        searchDisplay() {
            this.msg = ' '
            if (!this.searchInfo.tank) {
                this.msg = '請填寫完整桶槽資訊';
                this.$store.commit('SetSearchDisplayList', {})
            } else {
                this.GetSearchDisplayList({
                    tank_id: [this.searchInfo.tank.id]
                })
            }
        },
        testData() {
            this.tankInfo.radiusBottom -= 18;
            // console.log(this.tankInfo.radiusBottom);
            this.initThree();
        },
        hideDetail() {
            this.showDetail.show = false;
            var btn = document.getElementById('three');
            btn.click()
        },
        dot_redraw() {
            this.showDetail.isChange = true;
        }
    }
}
</script> 