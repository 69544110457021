<template>
    <div class="container-fluid">
        <div class="row px-4 pt-4">
            <div v-if="$store.state.userInfo.role.indexOf('Admin') != -1"
                class="col-3 col-xl-2 col-lg-2 col-md-2  mb-2">
                <label class="form-lable">所屬組織</label>
            </div>
            <div v-if="$store.state.userInfo.role.indexOf('Admin') != -1"
                class="col-9 col-xl-3 col-lg-4 col-md-10  mb-2">
                <Multiselect track-by="name" label="name" v-model="searchInfo.org" placeholder="請選擇"
                    :show-labels="false" :options="dropDownOrgList" :searchable="true" :allow-empty="true">
                </Multiselect>
            </div>
            <div class="col-3 col-xl-1 col-lg-2 col-md-2  mb-2">
                <label class="form-lable">地區</label>
            </div>
            <div class="col-9 col-xl-3 col-lg-4 col-md-10  mb-2">
                <Multiselect track-by="name" label="name" v-model="searchInfo.area" placeholder="請選擇"
                    :show-labels="false" :options="dropDownAreaList" :searchable="true" :allow-empty="true">
                </Multiselect>
            </div>
            <div class="col-3 col-xl-1 col-lg-2 col-md-2 mb-2">
                <label class="form-lable">桶槽</label>
            </div>
            <div class="col-9 col-xl-2 col-lg-4 col-md-10 mb-2">
                <Multiselect track-by="name" label="name" v-model="searchInfo.tank" placeholder="請選擇"
                    :show-labels="false" :options="dropDownTankList" :searchable="true" :allow-empty="true">
                </Multiselect>
            </div>
            <!-- <div class="col-4 col-lg-2 mb-2">
                <label class="form-lable">測量型態</label>
            </div>
            <div class="col-8 col-lg-4 mb-2">
                <Multiselect track-by="name" label="name" v-model="searchInfo.measurement_type" placeholder="請選擇"
                    :options="[{ name: '壁厚', id: 1 }, { name: '膜厚', id: 2 }]" :searchable="true" :allow-empty="true">
                </Multiselect>
            </div> -->
        </div>
        <div class="row px-4">
            <div class="col-4  col-xxl-1   col-lg-2 mb-2">
                <label class="form-lable">測量時間</label>
            </div>
            <div class="col-12 col-lg-6 mb-2" style="display: flex;
        gap: 7px;
        align-items: center;
      ">
                <VueDatePicker v-model="searchInfo.dateStart" placeholder="請選擇" type="date" :format="format">
                </VueDatePicker>
                ―
                <VueDatePicker v-model="searchInfo.dateEnd" placeholder="請選擇" type="date" :format="format">
                </VueDatePicker>
                <button class="dateClear-btn" @click="cleanDate" style="margin: 0px;"> ✕ </button>
            </div>
            <div class="col-12 col-lg-12  pb-4">
                <button class="search-btn" @click="ChangePage(1, 'search')">
                    搜尋
                </button>
            </div>
        </div>
        <div class="row px-4 pb-4"
            v-if="$store.state.userInfo.role.indexOf('Admin') != -1 || $store.state.userInfo.email == 'china_steel_admin@gmail.com'">
            <div class="col-12  " style="display: flex;justify-content: flex-end;">
                <button class="upload-btn" @click="popForm_show('create', {})">
                    <font-awesome-icon :icon="['fas', 'plus']" class="mx-1" />
                    上傳測量紀錄
                </button>
            </div>
        </div>
        <div class="row px-4  ">
            <div class="col-12 ">
                <div v-if="searchTankMeasurementList">
                    <!-- {{  searchTankMeasurementList.list }} -->
                </div>
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th style="width: 160px;">桶槽名稱</th>
                            <th style="width: 100px;">測量數量</th>
                            <th style="width: 100px;">測量型態</th>
                            <th>所屬組織</th>
                            <th>地區</th>
                            <th>桶槽半徑(m)</th>
                            <th>桶槽高度(m)</th>
                            <th>測量時間</th>
                            <th style="width:  60px;"> </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-if="!searchTankMeasurementList || !searchTankMeasurementList.list || searchTankMeasurementList.list.length == 0">
                            <td colspan="9" class="td_noData">Nodata</td>
                        </tr>
                        <template
                            v-if="searchTankMeasurementList && searchTankMeasurementList.list && searchTankMeasurementList.list.length != 0">
                            <tr v-for="(item, index) in  searchTankMeasurementList.list" :key="index">
                                <td>
                                    <span v-if="item.tank" v-bind:hovertext="item.tank.name"></span>{{ item.tank.name }}
                                </td>
                                <td>
                                    {{ item.tank_measurement_details.length }}
                                </td>
                                <td>
                                    {{ item.measurement_type.name }}
                                </td>
                                <td>
                                    {{ item.tank && item.tank.area && item.tank.area.organization ?
                item.tank.area.organization.name : '' }}
                                </td>
                                <td>
                                    {{ item.tank.area.name }}
                                </td>
                                <td>
                                    {{ item.tank.radius }}
                                </td>
                                <td>
                                    {{ item.tank.height }}
                                </td>
                                <td>
                                    <!-- {{ item.date.slice(0, 7) }} -->
                                    {{ item.date.slice(0, 10) }}
                                </td>
                                <td class="">
                                    <!-- <img src="../../assets/img/edit.png" class="list-icon"
                                        @click="popForm_show('edit', item)"> -->
                                    <img src="../../assets/img/delete.png" class="list-icon"
                                        @click="popForm_show('delete', item)">
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
            <div class="col-12">
                <PaginationVue v-bind:page-info="cm_pageInfo" v-bind:change-event="ChangePage"></PaginationVue>
            </div>
        </div>
    </div>
    <div class="pop-panel " v-if="popForm.show">
        <div class="pop-panel-container " v-bind:class="{ 'xl-panel': !(popForm.mode == 'delete') }">
            <div class="pop-panel-title" v-if="popForm.mode == 'upload'">上傳桶槽測量紀錄</div>
            <div class="pop-panel-title" v-if="popForm.mode == 'delete'">刪除桶槽測量紀錄</div>
            <div class="pop-panel-title" v-if="popForm.mode == 'edit'">編輯桶槽測量紀錄</div>
            <div class="pop-panel-title" v-if="popForm.mode == 'create'">上傳桶槽測量紀錄</div>
            <span class="xmark" @click="popForm_clean()">
                ✕
            </span>
            <div class="pop-panel-inner">
                <div class="row" v-if="popForm.mode == 'delete' || popForm.mode == 'download'">
                    <div class="col-12 mt-3 mb-4" style="color: gray;">請先輸入桶槽名稱才能進行刪除的動作，刪除此筆資料後，將無法還原。 </div>
                    <div class="col-3 mb-3">
                        <label class="form-lable require">桶槽名稱</label>
                    </div>
                    <div class="col-9 mb-3">
                        <input v-model="popForm.form.ckeckAgain" class="form-control" placeholder="請輸入" />
                    </div>
                    <div class="errMsg">{{ popForm.msg }}</div>
                    <div class="pop-panel-btn">
                        <button class="btn btn-outline-primary" @click="popForm_clean()">取消</button>
                        <button class="btn btn-primary " @click="popForm_save">確認</button>
                    </div>
                </div>
                <div class="row" v-if="popForm.mode != 'delete' && popForm.mode != 'download'">
                    <div class="row">
                        <div class="col-3 mb-3">
                            <label class="form-lable require">測量紀錄</label>
                        </div>
                        <div class="col-9 mb-3" v-if="popForm.mode == 'create' || popForm.mode == 'edit'">
                            <button class="addFile-btn" onclick="document.getElementById('getFile1').click()">
                                <font-awesome-icon :icon="['fas', 'plus']" class="me-2" />
                                選擇檔案
                            </button>
                            <div style="visibility: hidden;height: 0;">
                                <input type="file" id="getFile1" accept=".csv,.xls,.xlsx"
                                    @change="fileChange($event, 'file')" :key="popForm.form.file">
                            </div>
                            <div class=" mt-3 fileInfoGroup">
                                <span v-if="popForm.form.file" class="file-info">
                                    <img src="../../assets/img/file.png"> {{ popForm.form.file.name }} (
                                    {{ Math.round(popForm.form.file.size / 1000000 * 100) / 100 }}MB)
                                </span>
                                <!-- <img v-if="popForm.form.file" :src="previewObjectURL()" class="filePreview" alt=""> -->
                                <div class="errMsg"
                                    v-if="popForm.form.file && popForm.form.file.size / 1000000 * 100 / 100 > file_max">
                                    檔案超過{{ file_max }}MB無法上傳
                                </div>
                            </div>
                        </div>
                        <div class="col-3 mb-3">
                            <label class="form-lable require">測定報告</label>
                        </div>
                        <div class="col-9 mb-3" v-if="popForm.mode == 'create' || popForm.mode == 'edit'">
                            <button class="addFile-btn" onclick="document.getElementById('getFile2').click()">
                                <font-awesome-icon :icon="['fas', 'plus']" class="me-2" />
                                選擇檔案
                            </button>
                            <div style="visibility: hidden;height: 0;">
                                <input type="file" id="getFile2" accept=".pdf" @change="fileChange($event, 'report')"
                                    :key="popForm.form.report">
                            </div>
                            <div class=" mt-3 fileInfoGroup">
                                <span v-if="popForm.form.report" class="file-info">
                                    <img src="../../assets/img/file.png"> {{ popForm.form.report.name }} (
                                    {{ Math.round(popForm.form.report.size / 1000000 * 100) / 100 }}MB)
                                </span>
                                <!-- <img v-if="popForm.form.report" :src="previewObjectURL()" class="filePreview" alt=""> -->
                                <div class="errMsg"
                                    v-if="popForm.form.report && popForm.form.report.size / 1000000 * 100 / 100 > file_max">
                                    檔案超過{{ file_max }}MB無法上傳
                                </div>
                            </div>
                        </div>
                        <div class="col-3 mb-3">
                            <label class="form-lable require">桶槽名稱</label>
                        </div>
                        <div class="col-9 mb-3">
                            <Multiselect track-by="name" label="name" v-model="popForm.form.tank" placeholder="請選擇"
                                :options="dropDownTankList" :searchable="true" :allow-empty="true">
                            </Multiselect>
                        </div>
                        <div class="col-3 mb-3">
                            <label class="form-lable require">測量類型</label>
                        </div>
                        <div class="col-9 mb-3">
                            <Multiselect track-by="name" label="name" v-model="popForm.form.measurement_type"
                                placeholder="請選擇" :options="[{ name: '壁厚', id: 1 }, { name: '膜厚', id: 2 }]"
                                :searchable="true" :allow-empty="true">
                            </Multiselect>
                        </div>
                        <div class="col-3 mb-3">
                            <label class="form-lable require">測量時間</label>
                        </div>
                        <div class="col-9 mb-3">
                            <!-- month-picker  -->
                            <VueDatePicker v-model="popForm.form.date" placeholder="請選擇" :format="format">
                            </VueDatePicker>
                        </div>
                    </div>
                    <div class="errMsg">{{ popForm.msg }}</div>
                    <div class="cpp_btn">
                        <button class="btn btn-outline-primary" @click="popForm_clean()">取消</button>
                        <button class="btn btn-primary " @click="popForm_save">確認</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import { ConeGeometry } from 'three';
import PaginationVue from '../../components/layout/Pagination.vue'
import { mapState, mapActions } from 'vuex'
export default {
    searchUserList: {},
    name: 'KnowledgeManagement',
    components: {
        PaginationVue,
    },
    computed: {
        ...mapState(
            {
                dropDownOrgList: state => state.org.dropDown,
                dropDownAreaList: state => state.area.dropDown,
                dropDownTankList: state => state.tank.dropDown,
                searchTankMeasurementList: state => state.tankMeasurement.search,
                importTankMeasurement: state => state.tankMeasurement.import,
                deleteTankMeasurement: state => state.tankMeasurement.delete,
            }
        ),
    },
    data() {
        const format = (date) => {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            return `${year}/${month}/${day}`;
        }
        return {
            format,
            file_max: 4,
            tableModule: {
                searchHistory: null,
                searchCache: null,
                sort_order: "desc",
                sort_field: "id"
            },
            searchInfo: {
                name: null,
                checkedType: [],
                dateStart: null,
                dateEnd: null,
            },
            areaId: "",
            cm_pageInfo: {
                totalPage: 0,
                nowPage: 0,
            },
            popForm: {
                mode: "",
                show: false,
                itemData: {},
                formData: new FormData(),
                // formData_info: null,
                form: {
                    ckeckAgain: null,
                    tank: null,
                    times: 1,
                    measurement_type: null,
                    date: null,
                    file: null,
                    report: null,
                },
                msg: ""
            }
        };
    },
    mounted() {
        this.ChangePage(1)
        this.GetDropDownArea({
            "page": 1,
            "count": 100,
            "sort_field": "id",
            "sort_order": "desc",
            "is_output": 0
        });
        this.GetDropDownOrgList({
            "page": 1,
            "count": 100,
            "sort_field": "id",
            "sort_order": "desc",
            "is_output": 0
        });
        this.GetDropDownTank({
            "page": 1,
            "count": 100,
            "sort_field": "id",
            "sort_order": "desc",
            "is_output": 0
        });

    },
    watch: {
        searchTankMeasurementList: function (val) {
            this.cm_pageInfo = {
                totalPage: val.total_page,
                nowPage: val.page,
            }
            this.tableModule.searchHistory = this.tableModule.searchCache;
        },
        deleteTankMeasurement(val) {
            if (val.data.header.message == "Success") {
                this.popForm_clean();
                this.ChangePage(1);
            } else {
                this.popForm.msg = val.data.header.message;
                for (var i in val.data.data) {
                    this.popForm.msg = val.data.data[i][0];
                }
            }
        },
        importTankMeasurement: function (val) {
            if (val.data.header.message == "Success") {
                this.popForm_clean();
                this.GetSearchTankMeasurementList({
                    "page": 1,
                    "count": 10,
                    "sort_field": "id",
                    "sort_order": "desc",
                    "is_output": 0
                })
            } else {
                this.popForm.msg = val.data.header.message;
                for (var i in val.data.data) {
                    console.log(val.data.data);
                    this.popForm.msg = val.data.data[i][0];
                }
            }
        },
        'searchInfo.org': function (val) {
            if (!val) return;
            this.searchInfo.area = null;
            this.searchInfo.tank = null;
            this.GetDropDownArea({
                "organization_id": [val.id],
            });
            this.GetDropDownTank({
                "organization_id": [val.id],
            });
        },
        'searchInfo.area': function (val) {
            if (!val) return;
            this.searchInfo.tank = null;
            this.GetDropDownTank({
                "area_id": [val.id],
            });
        },
    },
    methods: {
        ...mapActions([
            'GetSearchTankMeasurementList',
            'GetDropDownArea',
            'GetDropDownTank',
            'GetImportTankMeasurement',
            'GetDeleteTankMeasurement',
            'GetDropDownOrgList'
        ]),
        popForm_show(_mode, _item) {
            this.popForm.show = true;
            this.popForm.mode = _mode;
            this.popForm.itemData = _item;
            // if (this.popForm.mode == "edit") {
            //     this.popForm.formData = new FormData();
            //     var file = this.dataURLtoFile('data:image/jpeg;base64,' + _item.image, "已上傳檔案");
            //     this.popForm.formData.append("image", file);
            //     console.log('file', file)
            //     // this.popForm.formData_info = file;
            //     this.popForm.form = {
            //         title: _item.title,
            //         id: _item.id,
            //         desc: _item.desc,
            //         content: _item.content,
            //         is_show: _item.is_show ? ['is_show'] : [],
            //         order: _item.order
            //     }
            // }
        },
        popForm_save() {
            this.popForm.msg = "";
            if (this.popForm.mode == "delete") {
                if (this.popForm.form.ckeckAgain != this.popForm.itemData.tank.name) {
                    this.popForm.msg = "請輸入正確桶槽名稱";
                    return;
                }
                this.GetDeleteTankMeasurement({
                    "id": this.popForm.itemData.id
                });
                return;
            }
            if (!this.popForm.form.file) {
                this.popForm.msg = "請上傳測量紀錄";
                return;
            }
            if (!this.popForm.form.report) {
                this.popForm.msg = "請上傳測定報告";
                return;
            }
            if (!this.popForm.form.tank) {
                this.popForm.msg = "桶槽名稱為必填";
                return;
            }
            if (!this.popForm.form.measurement_type) {
                this.popForm.msg = "測量類型為必填";
                return;
            }
            if (!this.popForm.form.date) {
                this.popForm.msg = "測量時間為必填";
                return;
            }

            this.popForm.formData = new FormData();
            this.popForm.formData.append("tank_id", this.popForm.form.tank.id)
            this.popForm.formData.append("times", 1)
            this.popForm.formData.append("measurement_type_id", this.popForm.form.measurement_type.id)
            console.log(this.popForm.form.date)
            this.popForm.formData.append("date", this.formatDate(this.popForm.form.date.toLocaleDateString('zh-TW')))
            this.popForm.formData.append("file", this.popForm.form.file)
            this.popForm.formData.append("report", this.popForm.form.report)

            if (this.popForm.mode == "create") {
                this.GetImportTankMeasurement(this.popForm.formData);
                return;
            }
            if (this.popForm.mode == "edit") {
                // payload.id = this.popForm.itemData.id;
                this.popForm.formData.append("id", this.popForm.form.id)
                //塞資料  
                this.GetUpdateKnowledge(this.popForm.formData);
                return;
            }
        },
        popForm_clean() {
            this.popForm = {
                mode: "",
                show: false,
                itemData: {},
                formData: new FormData(),
                form: {
                    ckeckAgain: null,
                    tank: null,
                    times: 1,
                    measurement_type: null,
                    date: null,
                    file: null,
                    report: null,
                },
                msg: ""
            }
        },
        ChangePage(num, mode) {
            var payload = {};
            if (mode == 'search' || !this.tableModule.searchHistory) {
                payload = {
                    "page": num,
                    "count": 10,
                    "sort_field": "id",
                    "sort_order": "asc",
                    "is_output": 0,
                    "search": {
                    }
                }
                // this.popForm.form.date.year + '-' + this.popForm.form.date.month + '-01'
                if (this.searchInfo.tank) payload.search.tank_id = [this.searchInfo.tank.id];
                if (this.searchInfo.area) payload.search.area_id = [this.searchInfo.area.id];
                if (this.searchInfo.org) payload.search.organization_id = [this.searchInfo.org.id];
                if (this.searchInfo.measurement_type) payload.search.measurement_type_id = [this.searchInfo.measurement_type.id];

                if (this.searchInfo.dateStart) payload.search.date_from = this.searchInfo.dateStart.toISOString().slice(0, 10);
                if (this.searchInfo.dateEnd) payload.search.date_end = this.searchInfo.dateEnd.toISOString().slice(0, 10);

                // if (this.searchInfo.dateStart) payload.search.date_from = this.searchInfo.dateStart.year + '-' + (this.searchInfo.dateStart.month < 9 ? "0" : "") + (this.searchInfo.dateStart.month + 1) + '-' + this.searchInfo.dateStart.date;
                // if (this.searchInfo.dateEnd) payload.search.date_end = this.searchInfo.dateEnd.year + '-' + (this.searchInfo.dateEnd.month < 9 ? "0" : "") + (this.searchInfo.dateEnd.month + 1) + '-' + this.searchInfo.dateEnd.date;
                this.tableModule.searchCache = payload;
            } else {
                this.tableModule.searchCache = this.tableModule.searchHistory;
                this.tableModule.searchCache.page = num;
                this.tableModule.searchCache.sort_field = this.tableModule.sort_field
                this.tableModule.searchCache.sort_order = this.tableModule.sort_order
            }
            this.tableModule.searchCache.is_output = mode == 'download' ? 1 : 0;
            this.GetSearchTankMeasurementList(this.tableModule.searchCache);
        },
        dataURLtoFile(dataurl, filename) {
            var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, { type: mime });
        },
        fileChange(e, key) {
            // this.popForm.formData = new FormData();
            // this.popForm.formData.append('image', e.target.files[0]); //放進上傳的檔案
            // this.popForm.formData_info = e.target.files[0];
            this.popForm.msg = null;
            if ((e.target.files[0].size / 1000000 * 100 / 100) >= this.file_max) {
                this.popForm.form[key] = null;
                this.popForm.msg = '檔案超過' + this.file_max + 'MB無法上傳';
                return
            }
            this.popForm.form[key] = e.target.files[0];
        },
        cleanDate() {
            this.searchInfo.dateEnd = null;
            this.searchInfo.dateStart = null;
        },
        formatDate(dateString) {
            const date = new Date(dateString);
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 月份從0開始，所以要加1
            const day = date.getDate().toString().padStart(2, '0');
            return `${year}-${month}-${day}`;
        }
    }
}
</script>