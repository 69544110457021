<template>
    <div v-if="Object.keys(measureData).length == 0" class="container-fluid p-4">
        <div class="row mt-5">
            <div class="col-12 mb-2">
                <h1 style="text-align: center;">ACR Gateway</h1>
            </div>
            <div class="col-12  offset-md-2 col-md-8  offset-lg-3 col-lg-6 mb-2 multiselect-group">
                <label class="form-lable">組 織</label>
                <Multiselect v-model="searchInfo.org" placeholder="Please select" :options="dropDownOrgList" track-by="id"
                    label="name" :searchable="true" :allow-empty="true">
                </Multiselect>
            </div>
        </div>
        <div class="row">
            <div class="col-12 offset-md-2 col-md-8  offset-lg-3 col-lg-6 mb-2 multiselect-group">
                <label class="form-lable">地 區</label>
                <Multiselect v-model="searchInfo.area" placeholder="Please select" :options="dropDownAreaList" track-by="id"
                    label="name" :searchable="true" :allow-empty="true">
                </Multiselect>
            </div>
        </div>
        <div class="row">
            <div class="col-12  offset-md-2 col-md-8 offset-lg-3  col-lg-6 mb-2 multiselect-group">
                <label class="form-lable">桶 槽</label>
                <Multiselect v-model="searchInfo.tank" placeholder="Please select" :options="dropDownTankList" track-by="id"
                    label="name" :searchable="true" :allow-empty="true">
                </Multiselect>
            </div>
        </div>
        <div class="row">
            <div class="col-12 ">
                <div class="errMsg">{{ msg }}</div>
                <button class="search-btn" @click="searchDisplay" style="margin: 15px auto;">
                    Search
                </button>
                <!-- <div v-if="Object.keys(searchDisplayList).length != 0">
                    {{ searchDisplayList }}
                </div> -->
            </div>
        </div>
    </div>
    <div v-if="Object.keys(measureData).length != 0" class="container-fluid p-4">
        <div class="row">
            <ThreeViewVue :key="threeViewVueKey" v-bind:measure-data="measureData" v-bind:close-view="closeThreeView">
            </ThreeViewVue>
            <div class="col-md-3"></div>
        </div>
    </div>
</template> 
<script>
import { mapState, mapActions } from 'vuex'
import ThreeViewVue from '../../components/display/unuse-ThreeView.vue';
export default {
    searchUserList: {},
    name: 'HcpUpload',
    components: {
        // DataTable,
        // PaginationVue
        ThreeViewVue
    },
    data() {
        return {
            msg: "",
            threeViewVueKey: 1,
            searchInfo: {
                org: null,
                area: null,
                tank: null,
            },
            measureData: {},
            filterInfo: {}
        };
    },
    watch: {
        'searchInfo.org': function (val) {
            if (val != null) {
                this.GetDropDownAreaList({
                    organization_id: [val.id]
                });
            }
            this.searchInfo.area = null
            this.searchInfo.tank = null

        },
        'searchInfo.area': function (val) {
            if (val != null) {
                this.GetDropDownTankList({
                    area_id: [val.id]
                });
            }
            this.searchInfo.tank = null

        },
        searchDisplayList: function (val) {
            console.log('searchDisplayList')
            this.msg = ""
            if (val.list && val.list[0])
                this.measureData = val;
            else this.msg = "此桶槽無測量資料"
        }
    },
    mounted() {
        this.GetDropDownOrgList({});
    },
    computed: {
        ...mapState(
            {
                dropDownOrgList: state => state.dropDown.org,
                dropDownAreaList: state => state.dropDown.area,
                dropDownTankList: state => state.dropDown.tank,
                searchDisplayList: state => state.search.display,
            }
        ),
    },
    methods: {
        ...mapActions([
            'GetDropDownOrgList',
            'GetDropDownAreaList',
            'GetDropDownTankList',
            'GetSearchDisplayList',
        ]),
        searchDisplay() {
            this.msg = ' '
            if (!this.searchInfo.tank) {
                this.msg = '請填寫完整桶槽資訊';
                this.$store.commit('SetSearchDisplayList', {})
                return
            } else {
                this.GetSearchDisplayList(
                    {
                        "page": 1,
                        "count": 10,
                        "sort_field": "id",
                        "sort_order": "desc",
                        "search": {
                            tank_id: [this.searchInfo.tank.id]
                        },
                        "is_output": 0
                    })
            }
            this.threeViewVueKey++;
        },
        closeThreeView() {
            this.measureData = {};
            this.threeViewVueKey++;
            this.msg = ""
        }
    }
}
</script> 