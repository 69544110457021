<template>
	<div class="container-fluid">
		<!-- <div class="row p-4">
			<div class="col-4 col-lg-2 mb-2">
				<label class="form-lable">地區名稱</label>
			</div>
			<div class="col-8 col-lg-4 mb-2">
				<input v-model="searchInfo.title" class="form-control" placeholder="Please enter" />
			</div>
			<div class="col-12 mt-3">
				<button class="search-btn" @click="ChangePage(1, 'search')">
					搜尋
				</button>
			</div>
		</div>
		<div class="row p-4">
			<div class="col-12  mb-3" style="display: flex;justify-content: flex-end;">
				<button class="upload-btn" @click="popForm_show('create', {})">
					<font-awesome-icon :icon="['fas', 'plus']" class="mx-1" />
					新增地區
				</button>
			</div>
		</div> -->
		<div class="row p-4 ">
			<div class="col-12 ">
				<table class="table table-bordered">
					<thead>
						<tr>
							<th>使用者名稱</th>
							<th style="width: 300px;">信箱</th>
							<th>所屬組織</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						<tr v-if="!searchUser || !searchUser.list || searchUser.list.length == 0">
							<td colspan="9" class="td_noData">Nodata</td>
						</tr>
						<template v-if="searchUser && searchUser.list && searchUser.list.length != 0">
							<tr v-for="(item, index) in searchUser.list" :key="index">
								<td>
									{{ item.name }}
								</td>
								<td>
									{{ item.email }}
								</td>
								<td>
									{{ item.organization ? item.organization.name : '-' }}
								</td>
								<td class="">
									<img src="../../assets/img/edit.png" class="list-icon"
										@click="popForm_show('edit', item)">
									<!-- <img src="../../assets/img/delete.png" class="list-icon"
										@click="popForm_show('delete', item)"> -->
								</td>
							</tr>
						</template>
					</tbody>
				</table>
			</div>
			<div class="col-12">
				<PaginationVue v-bind:page-info="cm_pageInfo" v-bind:change-event="ChangePage"></PaginationVue>
			</div>
		</div>
	</div>
	<div class="pop-panel " v-if="popForm.show">
		<div class="pop-panel-container " v-bind:class="{ 'xl-panel': !(popForm.mode == 'delete') }">
			<div class="pop-panel-title" v-if="popForm.mode == 'upload'">上傳</div>
			<div class="pop-panel-title" v-if="popForm.mode == 'delete'">刪除</div>
			<div class="pop-panel-title" v-if="popForm.mode == 'edit'">編輯</div>
			<div class="pop-panel-title" v-if="popForm.mode == 'create'">新增</div>
			<span class="xmark" @click="popForm_clean()">
				✕
			</span>
			<div class="pop-panel-inner">
				<div class="row" v-if="popForm.mode == 'delete' || popForm.mode == 'download'">
					<div class="col-12 pop-panel-text" v-if="popForm.mode == 'delete'">
						確定要刪除地區嗎？
					</div>
					<div class="pop-panel-btn">
						<button class="btn btn-outline-primary" @click="popForm_clean()">No</button>
						<button class="btn btn-primary " @click="popForm_save">Yes</button>
					</div>
				</div>
				<div class="row" v-if="popForm.mode != 'delete' && popForm.mode != 'download'">
					<div class="row">
						<div class="col-3 mb-3">
							<label class="form-lable require">使用者名稱</label>
						</div>
						<div class="col-9 mb-3">
							<input v-model="popForm.form.name" class="form-control" placeholder="Please enter" />
						</div>
						<!-- <div class="col-3 mb-3">
							<label class="form-lable require">所屬組織</label>
						</div>
						<div class="col-9 mb-3">
							<Multiselect v-model="popForm.form.department_id" placeholder="請選擇" :options="dropDownOrgList"
								track-by="id" :show-labels="false" label="name" :searchable="true" :allow-empty="true">
							</Multiselect>
						</div> -->
					</div>
					<div class="errMsg">{{ popForm.msg }}</div>
					<div class="cpp_btn">
						<button class="btn btn-outline-primary" @click="popForm_clean()">Cancel</button>
						<button class="btn btn-primary " @click="popForm_save">Save</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import 'datatables.net-select-bs5'
import PaginationVue from '../../components/layout/Pagination.vue'
import { mapState, mapActions, mapGetters } from 'vuex'
export default {
	searchUserList: {},
	name: 'UserManagement',
	components: {
		PaginationVue,
	},
	computed: {
		...mapState(
			{
				dropDownOrgList: state => state.org.dropDown,
				searchUser: state => state.user.search,
				createUser: state => state.user.create,
				deleteUser: state => state.user.delete,
				updateUser: state => state.user.update,
			}
		),
		...mapGetters(['CheckPermissions'])
	},
	data() {
		const format = (date) => {
			const day = date.getDate();
			const month = date.getMonth() + 1;
			const year = date.getFullYear();
			return `${year}/${month}/${day}`;
		}
		return {
			file_max: 100,
			tableModule: {
				searchHistory: null,
				searchCache: null,
				sort_order: "desc",
				sort_field: "id"
			},
			searchInfo: {
				name: null,
				checkedType: [],
				dateStart: null,
				dateEnd: null,
			},
			format,
			userId: "",
			cm_pageInfo: {
				totalPage: 0,
				nowPage: 0,
			},
			popForm: {
				mode: "",
				show: false,
				isStart: false,
				isStarted: false,
				timer: null,
				itemData: {},
				form: {
					article_type: null,
					title: null,
					desc: null,
					content: null,
					is_show: ['is_show'],
					order: 0
				},
				msg: ""
			}
		};
	},
	mounted() {
		this.ChangePage(1);
		this.GetDropDownOrgList({});
	},
	watch: {
		searchUser: function (val) {
			this.cm_pageInfo = {
				totalPage: val.total_page,
				nowPage: val.page,
			}
			this.tableModule.searchHistory = this.tableModule.searchCache;
		},
		createUser(val) {
			if (val.data.header.message == "Success") {
				this.popForm_clean();
				this.ChangePage(1);
			} else {
				this.popForm.msg = val.data.header.message;
				for (var i in val.data.data) {
					this.popForm.msg = val.data.data[i][0];
				}
			}
		},
		updateUser(val) {
			if (val.data.header.message == "Success") {
				this.popForm_clean();
				this.ChangePage(1);
			} else {
				this.popForm.msg = val.data.header.message;
				for (var i in val.data.data) {
					this.popForm.msg = val.data.data[i][0];
				}
			}
		},
		deleteUser(val) {
			if (val.data.header.message == "Success") {
				this.popForm_clean();
				this.ChangePage(1);
			} else {
				this.popForm.msg = val.data.header.message;
				for (var i in val.data.data) {
					this.popForm.msg = val.data.data[i][0];
				}
			}
		}
	},
	methods: {
		...mapActions([
			'GetDropDownOrgList',
			'GetDropDownUser',
			'GetSearchUser',
			'GetCreateUser',
			'GetDeleteUser',
			'GetUpdateUser'
		]),
		popForm_show(_mode, _item) {
			this.popForm.show = true;
			this.popForm.mode = _mode;
			this.popForm.itemData = _item;
			if (this.popForm.mode == "edit") {
				this.popForm.form = {
					// department_id: this.getOrgItem(_item.department_id),
					name: _item.name,
					organization_id: _item.organization.id,
					email: _item.email,
				}
			}
		},
		popForm_save() {
			this.popForm.msg = "";
			if (this.popForm.mode == "delete") {
				this.GetDeleteUser({
					"id": this.popForm.itemData.id
				});
				return;
			}

			if (!this.popForm.form.name) {
				this.popForm.msg = "使用者名稱 為必填欄位";
				return;
			}

			var payload;
			payload = {
				// "department_id": this.popForm.form.department_id.id,
				"name": this.popForm.form.name,
				"organization_id": this.popForm.form.organization_id,
				"email": this.popForm.form.email,
			};

			if (this.popForm.mode == "create") {
				this.GetCreateUser(payload);
				return;
			}
			if (this.popForm.mode == "edit") {
				payload.id = this.popForm.itemData.id;
				//塞資料  
				this.GetUpdateUser(payload);
				return;
			}
		},
		popForm_clean() {
			this.popForm = {
				mode: "",
				show: false,
				isStart: false,
				isStarted: false,
				timer: null,
				itemData: {},
				form: {
					article_type: null,
					title: null,
					desc: null,
					content: null,
					is_show: ['is_show'],
					order: 0
				},
				msg: ""
			}
		},
		ChangePage(num, mode) {
			var payload = {};
			if (mode == 'search' || !this.tableModule.searchHistory) {
				payload = {
					"page": num,
					"count": 10,
					"sort_field": "id",
					"sort_order": "asc",
					"is_output": 0,
					"search": {
						'email': [this.$store.state.userInfo.email]
					},
					"is_dropdown": 0,
				}
				if (this.searchInfo.title) payload.search.name = [this.searchInfo.title];
				if (this.searchInfo.article_type_id) payload.search.article_type_id = [this.searchInfo.article_type_id.id];
				this.tableModule.searchCache = payload;
			} else {
				this.tableModule.searchCache = this.tableModule.searchHistory;
				this.tableModule.searchCache.page = num;
				this.tableModule.searchCache.sort_field = this.tableModule.sort_field
				this.tableModule.searchCache.sort_order = this.tableModule.sort_order
			}
			this.tableModule.searchCache.is_output = mode == 'download' ? 1 : 0;
			this.GetSearchUser(this.tableModule.searchCache);
		},
		copyEvent(id) {
			var str = document.getElementById(id + '_link');
			window.getSelection().selectAllChildren(str);
			document.execCommand("Copy")
		},
		updateTiny(val) {
			console.log('updateTiny')
			this.popForm.form.content = val;
		},
		getOrgItem(id) {
			for (var i in this.dropDownOrgList) {
				if (this.dropDownOrgList[i].id == id) {
					return this.dropDownOrgList[i]
				}
			} return null
		},
	}
}
</script>
 