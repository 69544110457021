<template>
	<div class="container-fluid">
		<div class="row px-md-4 my-3">
			<div class="col-4 col-lg-2 mb-2">
				<label class="form-lable">頁面名稱</label>
			</div>
			<div class="col-8 col-lg-4 mb-2">
				<input v-model="searchInput" class="form-control" placeholder="Please enter" />
			</div>
		</div>
		<div class="row p-4 py-0 px-md-4 my-3">
			<div class="col-12 ">
				<table class="table table-bordered">
					<thead>
						<tr>
							<th>頁面名稱</th>
							<th>操作說明</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item, index) in search_instructions" :key="index">
							<td>{{ item.name }}</td>
							<td>
								<a v-bind:href="item.url" target="blank"> <img style="width: 35px;padding-right: 10px;"
										src="../../assets/img/file.png"> {{
					item.name }}.pdf</a>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	searchUserList: {},
	name: 'instructions',
	components: {
	},
	computed: {
		search_instructions: function () {
			var _arr = [];
			var text = this.searchInput;
			var userInfo_role = this.$store.state.userInfo.role
			this.instructions.forEach(_item => {
				if (_item.name.indexOf(text) != -1 || text == '') {
					if (_item.role == 'Admin') {
						if (userInfo_role.indexOf('Admin') != -1) _arr.push(_item)
					} else _arr.push(_item)
				}
			})
			return _arr;
		}
	},
	data() {
		return {
			searchInput: '',
			instructions: [//$store.state.userInfo.role.indexOf('Admin') != -1
				{ name: '桶槽測量紀錄', url: './桶槽測量紀錄.pdf', role: '' },
				{ name: '桶槽測量展示', url: './桶槽測量展示.pdf', role: '' },
				{ name: '地區管理', url: './地區管理.pdf', role: 'Admin' },
				{ name: '桶槽管理', url: './桶槽資訊管理.pdf', role: 'Admin' },
				{ name: '個人資訊管理', url: './個人資訊管理.pdf', role: '' },
				{ name: '部門管理', url: './部門管理.pdf', role: 'Admin' },
				{ name: '使用者管理', url: './使用者管理.pdf', role: 'Admin' },
			]
		};
	},
	mounted() {
	},
	watch: {
	},
	methods: {
	}
}
</script>