<template>
  <!-- <nav>
    <router-link to="/"> login</router-link> |
    <router-link to="/hpc-upload">hpc-upload</router-link>
  </nav> -->
  <router-view />
</template>

<style>
@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-vue-inputs/styles/material.css";
</style>