import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store' 
import axios from 'axios';
import VueAxios from 'vue-axios';

import 'bootstrap/dist/css/bootstrap.min.css'
import "bootstrap"   
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
 
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import "./assets/style/main.css"
import "./assets/style/layout.css"
import "./assets/style/global.css" 
// import Multiselect from '@vueform/multiselect'
 
import Multiselect from 'vue-multiselect'
library.add(fas)
const app = createApp(App);
app
.use(router)
.provide('$axios', axios)
.use(VueAxios, axios)
.use(store) 
.component('font-awesome-icon', FontAwesomeIcon) 
.component('Multiselect', Multiselect)
.component('VueDatePicker', VueDatePicker)
.mount('#app')

app.config.globalProperties.$axios = axios;
axios.defaults.withCredentials = true
axios.defaults.baseURL = '/web'

app.config.globalProperties.$Base64toBlob= function(b64Data, contentType, sliceSize)  {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;
  
    var byteCharacters = atob(b64Data);
    var byteArrays = [];
  
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
  
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      var byteArray = new Uint8Array(byteNumbers);
  
      byteArrays.push(byteArray);
    }
  
    var blob = new Blob(byteArrays, {type: contentType});
    return blob;
  };
  